// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file artifacts/store/v1/store_service.proto (package artifacts.store.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetArtifactRevisionPreviewUrlRequest, GetArtifactRevisionPreviewUrlResponse, GetArtifactRevisionRequest, GetArtifactRevisionResponse, PublishArtifactRequest, PublishArtifactResponse, UploadArtifactRevisionRequest, UploadArtifactRevisionResponse } from "./store_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "artifacts.store.v1.ArtifactStoreService";

/**
 * @generated from service artifacts.store.v1.ArtifactStoreService
 */
export const ArtifactStoreService = {
  typeName: "artifacts.store.v1.ArtifactStoreService",
  methods: {
    /**
     * @generated from rpc artifacts.store.v1.ArtifactStoreService.GetArtifactRevisionPreviewUrl
     */
    getArtifactRevisionPreviewUrl: {
      name: "GetArtifactRevisionPreviewUrl",
      I: GetArtifactRevisionPreviewUrlRequest,
      O: GetArtifactRevisionPreviewUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc artifacts.store.v1.ArtifactStoreService.GetArtifactRevision
     */
    getArtifactRevision: {
      name: "GetArtifactRevision",
      I: GetArtifactRevisionRequest,
      O: GetArtifactRevisionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc artifacts.store.v1.ArtifactStoreService.UploadArtifactRevision
     */
    uploadArtifactRevision: {
      name: "UploadArtifactRevision",
      I: UploadArtifactRevisionRequest,
      O: UploadArtifactRevisionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc artifacts.store.v1.ArtifactStoreService.PublishArtifact
     */
    publishArtifact: {
      name: "PublishArtifact",
      I: PublishArtifactRequest,
      O: PublishArtifactResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: ArtifactStoreService,});

/**
 * @generated from rpc artifacts.store.v1.ArtifactStoreService.GetArtifactRevisionPreviewUrl
 */
export const getArtifactRevisionPreviewUrl: UnaryFunctionsWithHooks<GetArtifactRevisionPreviewUrlRequest, GetArtifactRevisionPreviewUrlResponse> = {   ...$queryService.getArtifactRevisionPreviewUrl,  ...createUnaryHooks($queryService.getArtifactRevisionPreviewUrl)};

/**
 * @generated from rpc artifacts.store.v1.ArtifactStoreService.GetArtifactRevision
 */
export const getArtifactRevision: UnaryFunctionsWithHooks<GetArtifactRevisionRequest, GetArtifactRevisionResponse> = {   ...$queryService.getArtifactRevision,  ...createUnaryHooks($queryService.getArtifactRevision)};

/**
 * @generated from rpc artifacts.store.v1.ArtifactStoreService.UploadArtifactRevision
 */
export const uploadArtifactRevision: UnaryFunctionsWithHooks<UploadArtifactRevisionRequest, UploadArtifactRevisionResponse> = {   ...$queryService.uploadArtifactRevision,  ...createUnaryHooks($queryService.uploadArtifactRevision)};

/**
 * @generated from rpc artifacts.store.v1.ArtifactStoreService.PublishArtifact
 */
export const publishArtifact: UnaryFunctionsWithHooks<PublishArtifactRequest, PublishArtifactResponse> = {   ...$queryService.publishArtifact,  ...createUnaryHooks($queryService.publishArtifact)};
