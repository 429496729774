// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/company_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { RiskLevel } from "./risk_pb.js";
import { CompanyStatus } from "./company_status_pb.js";
import { ControlCountStat } from "./control_service_pb.js";
import { Comment } from "./comment_pb.js";
import { FindingStatus } from "./finding_service_pb.js";

/**
 * @generated from enum inventory.v1.PermissionCategory
 */
export enum PermissionCategory {
  /**
   * @generated from enum value: PERMISSION_CATEGORY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PERMISSION_CATEGORY_DATA_ACCESS = 1;
   */
  DATA_ACCESS = 1,

  /**
   * @generated from enum value: PERMISSION_CATEGORY_DATA_MODIFICATION = 2;
   */
  DATA_MODIFICATION = 2,

  /**
   * @generated from enum value: PERMISSION_CATEGORY_OPERATIONAL_ENVIRONMENT = 3;
   */
  OPERATIONAL_ENVIRONMENT = 3,

  /**
   * @generated from enum value: PERMISSION_CATEGORY_CONFIGURATION_PERMISSIONS = 4;
   */
  CONFIGURATION_PERMISSIONS = 4,

  /**
   * @generated from enum value: PERMISSION_CATEGORY_IDENTITY_AND_ACCESS_MANAGEMENT = 5;
   */
  IDENTITY_AND_ACCESS_MANAGEMENT = 5,

  /**
   * @generated from enum value: PERMISSION_CATEGORY_EMPLOYEE_DATA_ACCESS = 6;
   */
  EMPLOYEE_DATA_ACCESS = 6,

  /**
   * @generated from enum value: PERMISSION_CATEGORY_EMPLOYEE_DATA_MODIFICATION = 7;
   */
  EMPLOYEE_DATA_MODIFICATION = 7,

  /**
   * @generated from enum value: PERMISSION_CATEGORY_CLOUD_ENVIRONMENT = 8;
   */
  CLOUD_ENVIRONMENT = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(PermissionCategory)
proto3.util.setEnumType(PermissionCategory, "inventory.v1.PermissionCategory", [
  { no: 0, name: "PERMISSION_CATEGORY_UNSPECIFIED" },
  { no: 1, name: "PERMISSION_CATEGORY_DATA_ACCESS" },
  { no: 2, name: "PERMISSION_CATEGORY_DATA_MODIFICATION" },
  { no: 3, name: "PERMISSION_CATEGORY_OPERATIONAL_ENVIRONMENT" },
  { no: 4, name: "PERMISSION_CATEGORY_CONFIGURATION_PERMISSIONS" },
  { no: 5, name: "PERMISSION_CATEGORY_IDENTITY_AND_ACCESS_MANAGEMENT" },
  { no: 6, name: "PERMISSION_CATEGORY_EMPLOYEE_DATA_ACCESS" },
  { no: 7, name: "PERMISSION_CATEGORY_EMPLOYEE_DATA_MODIFICATION" },
  { no: 8, name: "PERMISSION_CATEGORY_CLOUD_ENVIRONMENT" },
]);

/**
 * @generated from enum inventory.v1.DataTypeSource
 */
export enum DataTypeSource {
  /**
   * @generated from enum value: DATA_TYPE_SOURCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DATA_TYPE_SOURCE_DOCUMENTED_ONLY = 1;
   */
  DOCUMENTED_ONLY = 1,

  /**
   * @generated from enum value: DATA_TYPE_SOURCE_DOCUMENTED_AND_ACCESSED = 2;
   */
  DOCUMENTED_AND_ACCESSED = 2,

  /**
   * @generated from enum value: DATA_TYPE_SOURCE_UNDOCUMENTED_ACCESS = 3;
   */
  UNDOCUMENTED_ACCESS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DataTypeSource)
proto3.util.setEnumType(DataTypeSource, "inventory.v1.DataTypeSource", [
  { no: 0, name: "DATA_TYPE_SOURCE_UNSPECIFIED" },
  { no: 1, name: "DATA_TYPE_SOURCE_DOCUMENTED_ONLY" },
  { no: 2, name: "DATA_TYPE_SOURCE_DOCUMENTED_AND_ACCESSED" },
  { no: 3, name: "DATA_TYPE_SOURCE_UNDOCUMENTED_ACCESS" },
]);

/**
 * @generated from enum inventory.v1.InherentRiskCategoryEnum
 */
export enum InherentRiskCategoryEnum {
  /**
   * @generated from enum value: INHERENT_RISK_CATEGORY_ENUM_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INHERENT_RISK_CATEGORY_ENUM_DATA = 1;
   */
  DATA = 1,

  /**
   * @generated from enum value: INHERENT_RISK_CATEGORY_ENUM_ATTACK_SURFACE = 2;
   */
  ATTACK_SURFACE = 2,

  /**
   * @generated from enum value: INHERENT_RISK_CATEGORY_ENUM_SPEND = 3;
   */
  SPEND = 3,

  /**
   * @generated from enum value: INHERENT_RISK_CATEGORY_ENUM_LOB = 4;
   */
  LOB = 4,

  /**
   * @generated from enum value: INHERENT_RISK_CATEGORY_ENUM_OUTAGE_OPERATIONAL_IMPACT = 5;
   */
  OUTAGE_OPERATIONAL_IMPACT = 5,

  /**
   * @generated from enum value: INHERENT_RISK_CATEGORY_ENUM_OUTAGE_BIZ_IMPACT = 6;
   */
  OUTAGE_BIZ_IMPACT = 6,

  /**
   * @generated from enum value: INHERENT_RISK_CATEGORY_ENUM_INTEG_METHOD = 7;
   */
  INTEG_METHOD = 7,

  /**
   * @generated from enum value: INHERENT_RISK_CATEGORY_ENUM_DATA_SUBPROCESSOR = 8;
   */
  DATA_SUBPROCESSOR = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(InherentRiskCategoryEnum)
proto3.util.setEnumType(InherentRiskCategoryEnum, "inventory.v1.InherentRiskCategoryEnum", [
  { no: 0, name: "INHERENT_RISK_CATEGORY_ENUM_UNSPECIFIED" },
  { no: 1, name: "INHERENT_RISK_CATEGORY_ENUM_DATA" },
  { no: 2, name: "INHERENT_RISK_CATEGORY_ENUM_ATTACK_SURFACE" },
  { no: 3, name: "INHERENT_RISK_CATEGORY_ENUM_SPEND" },
  { no: 4, name: "INHERENT_RISK_CATEGORY_ENUM_LOB" },
  { no: 5, name: "INHERENT_RISK_CATEGORY_ENUM_OUTAGE_OPERATIONAL_IMPACT" },
  { no: 6, name: "INHERENT_RISK_CATEGORY_ENUM_OUTAGE_BIZ_IMPACT" },
  { no: 7, name: "INHERENT_RISK_CATEGORY_ENUM_INTEG_METHOD" },
  { no: 8, name: "INHERENT_RISK_CATEGORY_ENUM_DATA_SUBPROCESSOR" },
]);

/**
 * @generated from enum inventory.v1.IRQChangeType
 */
export enum IRQChangeType {
  /**
   * @generated from enum value: IRQ_CHANGE_TYPE_UNSPECIFIED = 0;
   */
  IRQ_CHANGE_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: IRQ_CHANGE_TYPE_ADD = 1;
   */
  IRQ_CHANGE_TYPE_ADD = 1,

  /**
   * @generated from enum value: IRQ_CHANGE_TYPE_REMOVE = 2;
   */
  IRQ_CHANGE_TYPE_REMOVE = 2,

  /**
   * TODO: remove this enum value after we have assessment service
   *
   * @generated from enum value: IRQ_CHANGE_TYPE_FINISHED = 3;
   */
  IRQ_CHANGE_TYPE_FINISHED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(IRQChangeType)
proto3.util.setEnumType(IRQChangeType, "inventory.v1.IRQChangeType", [
  { no: 0, name: "IRQ_CHANGE_TYPE_UNSPECIFIED" },
  { no: 1, name: "IRQ_CHANGE_TYPE_ADD" },
  { no: 2, name: "IRQ_CHANGE_TYPE_REMOVE" },
  { no: 3, name: "IRQ_CHANGE_TYPE_FINISHED" },
]);

/**
 * @generated from enum inventory.v1.InherentRiskSource
 */
export enum InherentRiskSource {
  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_PROJECTED = 1;
   */
  PROJECTED = 1,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_IRQ = 2;
   */
  IRQ = 2,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_INTEGRATION = 3;
   */
  INTEGRATION = 3,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_MICROSOFT_ENTRA_ID = 4;
   */
  MICROSOFT_ENTRA_ID = 4,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_GOOGLE_WORKSPACE = 5;
   */
  GOOGLE_WORKSPACE = 5,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_ZIP = 6;
   */
  ZIP = 6,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_WIZ = 7;
   */
  WIZ = 7,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_NETSKOPE = 8;
   */
  NETSKOPE = 8,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_OKTA = 9;
   */
  OKTA = 9,

  /**
   * @generated from enum value: INHERENT_RISK_SOURCE_WEBHOOK = 10;
   */
  WEBHOOK = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(InherentRiskSource)
proto3.util.setEnumType(InherentRiskSource, "inventory.v1.InherentRiskSource", [
  { no: 0, name: "INHERENT_RISK_SOURCE_UNSPECIFIED" },
  { no: 1, name: "INHERENT_RISK_SOURCE_PROJECTED" },
  { no: 2, name: "INHERENT_RISK_SOURCE_IRQ" },
  { no: 3, name: "INHERENT_RISK_SOURCE_INTEGRATION" },
  { no: 4, name: "INHERENT_RISK_SOURCE_MICROSOFT_ENTRA_ID" },
  { no: 5, name: "INHERENT_RISK_SOURCE_GOOGLE_WORKSPACE" },
  { no: 6, name: "INHERENT_RISK_SOURCE_ZIP" },
  { no: 7, name: "INHERENT_RISK_SOURCE_WIZ" },
  { no: 8, name: "INHERENT_RISK_SOURCE_NETSKOPE" },
  { no: 9, name: "INHERENT_RISK_SOURCE_OKTA" },
  { no: 10, name: "INHERENT_RISK_SOURCE_WEBHOOK" },
]);

/**
 * @generated from message inventory.v1.CreateBusinessOwnerRequest
 */
export class CreateBusinessOwnerRequest extends Message<CreateBusinessOwnerRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  constructor(data?: PartialMessage<CreateBusinessOwnerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CreateBusinessOwnerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateBusinessOwnerRequest {
    return new CreateBusinessOwnerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateBusinessOwnerRequest {
    return new CreateBusinessOwnerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateBusinessOwnerRequest {
    return new CreateBusinessOwnerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateBusinessOwnerRequest | PlainMessage<CreateBusinessOwnerRequest> | undefined, b: CreateBusinessOwnerRequest | PlainMessage<CreateBusinessOwnerRequest> | undefined): boolean {
    return proto3.util.equals(CreateBusinessOwnerRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.CreateBusinessOwnerResponse
 */
export class CreateBusinessOwnerResponse extends Message<CreateBusinessOwnerResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CreateBusinessOwnerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CreateBusinessOwnerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateBusinessOwnerResponse {
    return new CreateBusinessOwnerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateBusinessOwnerResponse {
    return new CreateBusinessOwnerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateBusinessOwnerResponse {
    return new CreateBusinessOwnerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateBusinessOwnerResponse | PlainMessage<CreateBusinessOwnerResponse> | undefined, b: CreateBusinessOwnerResponse | PlainMessage<CreateBusinessOwnerResponse> | undefined): boolean {
    return proto3.util.equals(CreateBusinessOwnerResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateBusinessOwnerRequest
 */
export class UpdateBusinessOwnerRequest extends Message<UpdateBusinessOwnerRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * if not set, we will unset the business owner
   *
   * @generated from field: optional string business_owner_id = 2;
   */
  businessOwnerId?: string;

  constructor(data?: PartialMessage<UpdateBusinessOwnerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateBusinessOwnerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "business_owner_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBusinessOwnerRequest {
    return new UpdateBusinessOwnerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBusinessOwnerRequest {
    return new UpdateBusinessOwnerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBusinessOwnerRequest {
    return new UpdateBusinessOwnerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBusinessOwnerRequest | PlainMessage<UpdateBusinessOwnerRequest> | undefined, b: UpdateBusinessOwnerRequest | PlainMessage<UpdateBusinessOwnerRequest> | undefined): boolean {
    return proto3.util.equals(UpdateBusinessOwnerRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateBusinessOwnerResponse
 */
export class UpdateBusinessOwnerResponse extends Message<UpdateBusinessOwnerResponse> {
  constructor(data?: PartialMessage<UpdateBusinessOwnerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateBusinessOwnerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBusinessOwnerResponse {
    return new UpdateBusinessOwnerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBusinessOwnerResponse {
    return new UpdateBusinessOwnerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBusinessOwnerResponse {
    return new UpdateBusinessOwnerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBusinessOwnerResponse | PlainMessage<UpdateBusinessOwnerResponse> | undefined, b: UpdateBusinessOwnerResponse | PlainMessage<UpdateBusinessOwnerResponse> | undefined): boolean {
    return proto3.util.equals(UpdateBusinessOwnerResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.AutocompleteBusinessOwnerRequest
 */
export class AutocompleteBusinessOwnerRequest extends Message<AutocompleteBusinessOwnerRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: string search = 2;
   */
  search = "";

  constructor(data?: PartialMessage<AutocompleteBusinessOwnerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AutocompleteBusinessOwnerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteBusinessOwnerRequest {
    return new AutocompleteBusinessOwnerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteBusinessOwnerRequest {
    return new AutocompleteBusinessOwnerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteBusinessOwnerRequest {
    return new AutocompleteBusinessOwnerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteBusinessOwnerRequest | PlainMessage<AutocompleteBusinessOwnerRequest> | undefined, b: AutocompleteBusinessOwnerRequest | PlainMessage<AutocompleteBusinessOwnerRequest> | undefined): boolean {
    return proto3.util.equals(AutocompleteBusinessOwnerRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.AutocompleteBusinessOwnerResponse
 */
export class AutocompleteBusinessOwnerResponse extends Message<AutocompleteBusinessOwnerResponse> {
  /**
   * @generated from field: repeated inventory.v1.BusinessOwner business_owners = 1;
   */
  businessOwners: BusinessOwner[] = [];

  constructor(data?: PartialMessage<AutocompleteBusinessOwnerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AutocompleteBusinessOwnerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "business_owners", kind: "message", T: BusinessOwner, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteBusinessOwnerResponse {
    return new AutocompleteBusinessOwnerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteBusinessOwnerResponse {
    return new AutocompleteBusinessOwnerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteBusinessOwnerResponse {
    return new AutocompleteBusinessOwnerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteBusinessOwnerResponse | PlainMessage<AutocompleteBusinessOwnerResponse> | undefined, b: AutocompleteBusinessOwnerResponse | PlainMessage<AutocompleteBusinessOwnerResponse> | undefined): boolean {
    return proto3.util.equals(AutocompleteBusinessOwnerResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListInherentRiskCategoriesRequest
 */
export class ListInherentRiskCategoriesRequest extends Message<ListInherentRiskCategoriesRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<ListInherentRiskCategoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListInherentRiskCategoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInherentRiskCategoriesRequest {
    return new ListInherentRiskCategoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInherentRiskCategoriesRequest {
    return new ListInherentRiskCategoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListInherentRiskCategoriesRequest {
    return new ListInherentRiskCategoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListInherentRiskCategoriesRequest | PlainMessage<ListInherentRiskCategoriesRequest> | undefined, b: ListInherentRiskCategoriesRequest | PlainMessage<ListInherentRiskCategoriesRequest> | undefined): boolean {
    return proto3.util.equals(ListInherentRiskCategoriesRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListInherentRiskCategoriesResponse
 */
export class ListInherentRiskCategoriesResponse extends Message<ListInherentRiskCategoriesResponse> {
  /**
   * @generated from field: repeated inventory.v1.InherentRiskCategory categories = 1;
   */
  categories: InherentRiskCategory[] = [];

  constructor(data?: PartialMessage<ListInherentRiskCategoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListInherentRiskCategoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "message", T: InherentRiskCategory, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInherentRiskCategoriesResponse {
    return new ListInherentRiskCategoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInherentRiskCategoriesResponse {
    return new ListInherentRiskCategoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListInherentRiskCategoriesResponse {
    return new ListInherentRiskCategoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListInherentRiskCategoriesResponse | PlainMessage<ListInherentRiskCategoriesResponse> | undefined, b: ListInherentRiskCategoriesResponse | PlainMessage<ListInherentRiskCategoriesResponse> | undefined): boolean {
    return proto3.util.equals(ListInherentRiskCategoriesResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.SetIRQItemRequest
 */
export class SetIRQItemRequest extends Message<SetIRQItemRequest> {
  /**
   * @generated from field: string risk_category_id = 1;
   */
  riskCategoryId = "";

  /**
   * @generated from field: string company_id = 2;
   */
  companyId = "";

  /**
   * @generated from field: inventory.v1.IRQChangeType change_type = 3;
   */
  changeType = IRQChangeType.IRQ_CHANGE_TYPE_UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.InherentRiskCategoryEnum category = 4;
   */
  category = InherentRiskCategoryEnum.UNSPECIFIED;

  /**
   * @generated from field: optional string comment = 5;
   */
  comment?: string;

  /**
   * @generated from field: optional inventory.v1.InherentRiskSource source = 6;
   */
  source?: InherentRiskSource;

  /**
   * @generated from field: optional string content = 7;
   */
  content?: string;

  constructor(data?: PartialMessage<SetIRQItemRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SetIRQItemRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "risk_category_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "change_type", kind: "enum", T: proto3.getEnumType(IRQChangeType) },
    { no: 4, name: "category", kind: "enum", T: proto3.getEnumType(InherentRiskCategoryEnum) },
    { no: 5, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "source", kind: "enum", T: proto3.getEnumType(InherentRiskSource), opt: true },
    { no: 7, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetIRQItemRequest {
    return new SetIRQItemRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetIRQItemRequest {
    return new SetIRQItemRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetIRQItemRequest {
    return new SetIRQItemRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetIRQItemRequest | PlainMessage<SetIRQItemRequest> | undefined, b: SetIRQItemRequest | PlainMessage<SetIRQItemRequest> | undefined): boolean {
    return proto3.util.equals(SetIRQItemRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.SetIRQItemResponse
 */
export class SetIRQItemResponse extends Message<SetIRQItemResponse> {
  constructor(data?: PartialMessage<SetIRQItemResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SetIRQItemResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetIRQItemResponse {
    return new SetIRQItemResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetIRQItemResponse {
    return new SetIRQItemResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetIRQItemResponse {
    return new SetIRQItemResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetIRQItemResponse | PlainMessage<SetIRQItemResponse> | undefined, b: SetIRQItemResponse | PlainMessage<SetIRQItemResponse> | undefined): boolean {
    return proto3.util.equals(SetIRQItemResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListCompaniesRequest
 */
export class ListCompaniesRequest extends Message<ListCompaniesRequest> {
  constructor(data?: PartialMessage<ListCompaniesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListCompaniesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCompaniesRequest {
    return new ListCompaniesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCompaniesRequest {
    return new ListCompaniesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCompaniesRequest {
    return new ListCompaniesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListCompaniesRequest | PlainMessage<ListCompaniesRequest> | undefined, b: ListCompaniesRequest | PlainMessage<ListCompaniesRequest> | undefined): boolean {
    return proto3.util.equals(ListCompaniesRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListCompaniesResponse
 */
export class ListCompaniesResponse extends Message<ListCompaniesResponse> {
  /**
   * @generated from field: repeated inventory.v1.Company companies = 1;
   */
  companies: Company[] = [];

  constructor(data?: PartialMessage<ListCompaniesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListCompaniesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "companies", kind: "message", T: Company, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCompaniesResponse {
    return new ListCompaniesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCompaniesResponse {
    return new ListCompaniesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCompaniesResponse {
    return new ListCompaniesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCompaniesResponse | PlainMessage<ListCompaniesResponse> | undefined, b: ListCompaniesResponse | PlainMessage<ListCompaniesResponse> | undefined): boolean {
    return proto3.util.equals(ListCompaniesResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCompanyRequest
 */
export class GetCompanyRequest extends Message<GetCompanyRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetCompanyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCompanyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCompanyRequest {
    return new GetCompanyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCompanyRequest {
    return new GetCompanyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCompanyRequest {
    return new GetCompanyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCompanyRequest | PlainMessage<GetCompanyRequest> | undefined, b: GetCompanyRequest | PlainMessage<GetCompanyRequest> | undefined): boolean {
    return proto3.util.equals(GetCompanyRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCompanyResponse
 */
export class GetCompanyResponse extends Message<GetCompanyResponse> {
  /**
   * @generated from field: inventory.v1.Company company = 1;
   */
  company?: Company;

  constructor(data?: PartialMessage<GetCompanyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCompanyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company", kind: "message", T: Company },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCompanyResponse {
    return new GetCompanyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCompanyResponse {
    return new GetCompanyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCompanyResponse {
    return new GetCompanyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCompanyResponse | PlainMessage<GetCompanyResponse> | undefined, b: GetCompanyResponse | PlainMessage<GetCompanyResponse> | undefined): boolean {
    return proto3.util.equals(GetCompanyResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCompanyDrawerMetadataRequest
 */
export class GetCompanyDrawerMetadataRequest extends Message<GetCompanyDrawerMetadataRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: optional string solution_id = 2;
   */
  solutionId?: string;

  /**
   * @generated from field: bool with_users = 3;
   */
  withUsers = false;

  constructor(data?: PartialMessage<GetCompanyDrawerMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCompanyDrawerMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "solution_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "with_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCompanyDrawerMetadataRequest {
    return new GetCompanyDrawerMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCompanyDrawerMetadataRequest {
    return new GetCompanyDrawerMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCompanyDrawerMetadataRequest {
    return new GetCompanyDrawerMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCompanyDrawerMetadataRequest | PlainMessage<GetCompanyDrawerMetadataRequest> | undefined, b: GetCompanyDrawerMetadataRequest | PlainMessage<GetCompanyDrawerMetadataRequest> | undefined): boolean {
    return proto3.util.equals(GetCompanyDrawerMetadataRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.BusinessOwner
 */
export class BusinessOwner extends Message<BusinessOwner> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: optional google.protobuf.Timestamp last_request_at = 4;
   */
  lastRequestAt?: Timestamp;

  /**
   * @generated from field: optional inventory.v1.Source source = 5;
   */
  source?: Source;

  constructor(data?: PartialMessage<BusinessOwner>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.BusinessOwner";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_request_at", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "source", kind: "message", T: Source, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BusinessOwner {
    return new BusinessOwner().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BusinessOwner {
    return new BusinessOwner().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BusinessOwner {
    return new BusinessOwner().fromJsonString(jsonString, options);
  }

  static equals(a: BusinessOwner | PlainMessage<BusinessOwner> | undefined, b: BusinessOwner | PlainMessage<BusinessOwner> | undefined): boolean {
    return proto3.util.equals(BusinessOwner, a, b);
  }
}

/**
 * @generated from message inventory.v1.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: google.protobuf.Timestamp last_seen = 5;
   */
  lastSeen?: Timestamp;

  /**
   * @generated from field: repeated inventory.v1.Source sources = 6;
   */
  sources: Source[] = [];

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "last_seen", kind: "message", T: Timestamp },
    { no: 6, name: "sources", kind: "message", T: Source, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCompanyDrawerMetadataResponse
 */
export class GetCompanyDrawerMetadataResponse extends Message<GetCompanyDrawerMetadataResponse> {
  /**
   * these fields change depending on the given solution_id
   *
   * @generated from field: int32 num_users = 1;
   */
  numUsers = 0;

  /**
   * @generated from field: map<string, int32> num_users_by_organization_unit = 2;
   */
  numUsersByOrganizationUnit: { [key: string]: number } = {};

  /**
   * @generated from field: repeated inventory.v1.DataTypeInfo data_types = 3;
   */
  dataTypes: DataTypeInfo[] = [];

  /**
   * @generated from field: repeated inventory.v1.Permission permissions = 4;
   */
  permissions: Permission[] = [];

  /**
   * @generated from field: repeated inventory.v1.Asset assets = 5;
   */
  assets: Asset[] = [];

  /**
   * @generated from field: repeated inventory.v1.User users = 6;
   */
  users: User[] = [];

  /**
   * @generated from field: repeated inventory.v1.Solution solutions = 7;
   */
  solutions: Solution[] = [];

  /**
   * @generated from field: optional inventory.v1.BusinessOwner business_owner = 8;
   */
  businessOwner?: BusinessOwner;

  constructor(data?: PartialMessage<GetCompanyDrawerMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCompanyDrawerMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "num_users", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "num_users_by_organization_unit", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
    { no: 3, name: "data_types", kind: "message", T: DataTypeInfo, repeated: true },
    { no: 4, name: "permissions", kind: "message", T: Permission, repeated: true },
    { no: 5, name: "assets", kind: "message", T: Asset, repeated: true },
    { no: 6, name: "users", kind: "message", T: User, repeated: true },
    { no: 7, name: "solutions", kind: "message", T: Solution, repeated: true },
    { no: 8, name: "business_owner", kind: "message", T: BusinessOwner, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCompanyDrawerMetadataResponse {
    return new GetCompanyDrawerMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCompanyDrawerMetadataResponse {
    return new GetCompanyDrawerMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCompanyDrawerMetadataResponse {
    return new GetCompanyDrawerMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCompanyDrawerMetadataResponse | PlainMessage<GetCompanyDrawerMetadataResponse> | undefined, b: GetCompanyDrawerMetadataResponse | PlainMessage<GetCompanyDrawerMetadataResponse> | undefined): boolean {
    return proto3.util.equals(GetCompanyDrawerMetadataResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateCompanyRequest
 */
export class UpdateCompanyRequest extends Message<UpdateCompanyRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional inventory.v1.RiskLevel company_risk_level = 2;
   */
  companyRiskLevel?: RiskLevel;

  /**
   * @generated from field: optional inventory.v1.CompanyStatus status = 3;
   */
  status?: CompanyStatus;

  /**
   * @generated from field: optional string comment = 4;
   */
  comment?: string;

  constructor(data?: PartialMessage<UpdateCompanyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateCompanyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel), opt: true },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(CompanyStatus), opt: true },
    { no: 4, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCompanyRequest {
    return new UpdateCompanyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCompanyRequest {
    return new UpdateCompanyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCompanyRequest {
    return new UpdateCompanyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCompanyRequest | PlainMessage<UpdateCompanyRequest> | undefined, b: UpdateCompanyRequest | PlainMessage<UpdateCompanyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCompanyRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateCompanyResponse
 */
export class UpdateCompanyResponse extends Message<UpdateCompanyResponse> {
  constructor(data?: PartialMessage<UpdateCompanyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateCompanyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCompanyResponse {
    return new UpdateCompanyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCompanyResponse {
    return new UpdateCompanyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCompanyResponse {
    return new UpdateCompanyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCompanyResponse | PlainMessage<UpdateCompanyResponse> | undefined, b: UpdateCompanyResponse | PlainMessage<UpdateCompanyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateCompanyResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetInherentRiskRequest
 */
export class GetInherentRiskRequest extends Message<GetInherentRiskRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<GetInherentRiskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetInherentRiskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInherentRiskRequest {
    return new GetInherentRiskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInherentRiskRequest {
    return new GetInherentRiskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInherentRiskRequest {
    return new GetInherentRiskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInherentRiskRequest | PlainMessage<GetInherentRiskRequest> | undefined, b: GetInherentRiskRequest | PlainMessage<GetInherentRiskRequest> | undefined): boolean {
    return proto3.util.equals(GetInherentRiskRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetInherentRiskResponse
 */
export class GetInherentRiskResponse extends Message<GetInherentRiskResponse> {
  /**
   * @generated from field: repeated inventory.v1.InherentRiskGroup inherent_risk_groups = 1;
   */
  inherentRiskGroups: InherentRiskGroup[] = [];

  constructor(data?: PartialMessage<GetInherentRiskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetInherentRiskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inherent_risk_groups", kind: "message", T: InherentRiskGroup, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInherentRiskResponse {
    return new GetInherentRiskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInherentRiskResponse {
    return new GetInherentRiskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInherentRiskResponse {
    return new GetInherentRiskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetInherentRiskResponse | PlainMessage<GetInherentRiskResponse> | undefined, b: GetInherentRiskResponse | PlainMessage<GetInherentRiskResponse> | undefined): boolean {
    return proto3.util.equals(GetInherentRiskResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCompanyCISOProfileRequest
 */
export class GetCompanyCISOProfileRequest extends Message<GetCompanyCISOProfileRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<GetCompanyCISOProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCompanyCISOProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCompanyCISOProfileRequest {
    return new GetCompanyCISOProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCompanyCISOProfileRequest {
    return new GetCompanyCISOProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCompanyCISOProfileRequest {
    return new GetCompanyCISOProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCompanyCISOProfileRequest | PlainMessage<GetCompanyCISOProfileRequest> | undefined, b: GetCompanyCISOProfileRequest | PlainMessage<GetCompanyCISOProfileRequest> | undefined): boolean {
    return proto3.util.equals(GetCompanyCISOProfileRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCompanyCISOProfileResponse
 */
export class GetCompanyCISOProfileResponse extends Message<GetCompanyCISOProfileResponse> {
  /**
   * @generated from field: inventory.v1.PersonProfile ciso_profile = 1;
   */
  cisoProfile?: PersonProfile;

  constructor(data?: PartialMessage<GetCompanyCISOProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCompanyCISOProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ciso_profile", kind: "message", T: PersonProfile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCompanyCISOProfileResponse {
    return new GetCompanyCISOProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCompanyCISOProfileResponse {
    return new GetCompanyCISOProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCompanyCISOProfileResponse {
    return new GetCompanyCISOProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCompanyCISOProfileResponse | PlainMessage<GetCompanyCISOProfileResponse> | undefined, b: GetCompanyCISOProfileResponse | PlainMessage<GetCompanyCISOProfileResponse> | undefined): boolean {
    return proto3.util.equals(GetCompanyCISOProfileResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCompanyActivityLogRequest
 */
export class GetCompanyActivityLogRequest extends Message<GetCompanyActivityLogRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<GetCompanyActivityLogRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCompanyActivityLogRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCompanyActivityLogRequest {
    return new GetCompanyActivityLogRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCompanyActivityLogRequest {
    return new GetCompanyActivityLogRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCompanyActivityLogRequest {
    return new GetCompanyActivityLogRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCompanyActivityLogRequest | PlainMessage<GetCompanyActivityLogRequest> | undefined, b: GetCompanyActivityLogRequest | PlainMessage<GetCompanyActivityLogRequest> | undefined): boolean {
    return proto3.util.equals(GetCompanyActivityLogRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetCompanyActivityLogResponse
 */
export class GetCompanyActivityLogResponse extends Message<GetCompanyActivityLogResponse> {
  /**
   * @generated from field: repeated inventory.v1.CompanyActivityLogItem items = 1;
   */
  items: CompanyActivityLogItem[] = [];

  constructor(data?: PartialMessage<GetCompanyActivityLogResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetCompanyActivityLogResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: CompanyActivityLogItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCompanyActivityLogResponse {
    return new GetCompanyActivityLogResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCompanyActivityLogResponse {
    return new GetCompanyActivityLogResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCompanyActivityLogResponse {
    return new GetCompanyActivityLogResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCompanyActivityLogResponse | PlainMessage<GetCompanyActivityLogResponse> | undefined, b: GetCompanyActivityLogResponse | PlainMessage<GetCompanyActivityLogResponse> | undefined): boolean {
    return proto3.util.equals(GetCompanyActivityLogResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.Solution
 */
export class Solution extends Message<Solution> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: inventory.v1.Source source = 6;
   */
  source?: Source;

  constructor(data?: PartialMessage<Solution>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Solution";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "source", kind: "message", T: Source },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Solution {
    return new Solution().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Solution {
    return new Solution().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Solution {
    return new Solution().fromJsonString(jsonString, options);
  }

  static equals(a: Solution | PlainMessage<Solution> | undefined, b: Solution | PlainMessage<Solution> | undefined): boolean {
    return proto3.util.equals(Solution, a, b);
  }
}

/**
 * @generated from message inventory.v1.Company
 */
export class Company extends Message<Company> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: inventory.v1.CompanyProfile profile = 2;
   */
  profile?: CompanyProfile;

  /**
   * @generated from field: inventory.v1.RiskLevel risk = 3;
   */
  risk = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.CompanyStatus status = 4;
   */
  status = CompanyStatus.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp status_last_update = 5;
   */
  statusLastUpdate?: Timestamp;

  /**
   * @generated from field: string spend = 7;
   */
  spend = "";

  /**
   * @generated from field: repeated inventory.v1.FindingCountStat findings_by_level = 8;
   */
  findingsByLevel: FindingCountStat[] = [];

  /**
   * @generated from field: repeated inventory.v1.DataTypeInfo data_types = 10;
   */
  dataTypes: DataTypeInfo[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 13;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: int32 solution_count = 14;
   */
  solutionCount = 0;

  /**
   * @generated from field: repeated string solutions = 15;
   */
  solutions: string[] = [];

  /**
   * @generated from field: repeated inventory.v1.Source sources = 16;
   */
  sources: Source[] = [];

  /**
   * @generated from field: inventory.v1.RiskLevel calculated_risk = 17;
   */
  calculatedRisk = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: bool is_subprocessor = 18;
   */
  isSubprocessor = false;

  /**
   * @generated from field: bool is_suggested_subprocessor = 19;
   */
  isSuggestedSubprocessor = false;

  /**
   * @generated from field: optional string business_owner_name = 20;
   */
  businessOwnerName?: string;

  /**
   * @generated from field: repeated inventory.v1.ControlCountStat controls_by_status = 21;
   */
  controlsByStatus: ControlCountStat[] = [];

  /**
   * @generated from field: int32 total_questionnaires = 22;
   */
  totalQuestionnaires = 0;

  /**
   * @generated from field: int32 total_ongoing_questionnaires = 23;
   */
  totalOngoingQuestionnaires = 0;

  constructor(data?: PartialMessage<Company>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Company";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "profile", kind: "message", T: CompanyProfile },
    { no: 3, name: "risk", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(CompanyStatus) },
    { no: 5, name: "status_last_update", kind: "message", T: Timestamp },
    { no: 7, name: "spend", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "findings_by_level", kind: "message", T: FindingCountStat, repeated: true },
    { no: 10, name: "data_types", kind: "message", T: DataTypeInfo, repeated: true },
    { no: 13, name: "created_at", kind: "message", T: Timestamp },
    { no: 14, name: "solution_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "solutions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "sources", kind: "message", T: Source, repeated: true },
    { no: 17, name: "calculated_risk", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 18, name: "is_subprocessor", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "is_suggested_subprocessor", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "business_owner_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "controls_by_status", kind: "message", T: ControlCountStat, repeated: true },
    { no: 22, name: "total_questionnaires", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 23, name: "total_ongoing_questionnaires", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Company {
    return new Company().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Company {
    return new Company().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Company {
    return new Company().fromJsonString(jsonString, options);
  }

  static equals(a: Company | PlainMessage<Company> | undefined, b: Company | PlainMessage<Company> | undefined): boolean {
    return proto3.util.equals(Company, a, b);
  }
}

/**
 * @generated from message inventory.v1.FindingCountStat
 */
export class FindingCountStat extends Message<FindingCountStat> {
  /**
   * @generated from field: inventory.v1.RiskLevel risk_level = 1;
   */
  riskLevel = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<FindingCountStat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FindingCountStat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindingCountStat {
    return new FindingCountStat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindingCountStat {
    return new FindingCountStat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindingCountStat {
    return new FindingCountStat().fromJsonString(jsonString, options);
  }

  static equals(a: FindingCountStat | PlainMessage<FindingCountStat> | undefined, b: FindingCountStat | PlainMessage<FindingCountStat> | undefined): boolean {
    return proto3.util.equals(FindingCountStat, a, b);
  }
}

/**
 * @generated from message inventory.v1.Permission
 */
export class Permission extends Message<Permission> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: repeated inventory.v1.PermissionCategory category = 3;
   */
  category: PermissionCategory[] = [];

  /**
   * @generated from field: inventory.v1.Source source = 4;
   */
  source?: Source;

  constructor(data?: PartialMessage<Permission>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Permission";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "category", kind: "enum", T: proto3.getEnumType(PermissionCategory), repeated: true },
    { no: 4, name: "source", kind: "message", T: Source },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Permission {
    return new Permission().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Permission {
    return new Permission().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Permission {
    return new Permission().fromJsonString(jsonString, options);
  }

  static equals(a: Permission | PlainMessage<Permission> | undefined, b: Permission | PlainMessage<Permission> | undefined): boolean {
    return proto3.util.equals(Permission, a, b);
  }
}

/**
 * @generated from message inventory.v1.CompanyProfile
 */
export class CompanyProfile extends Message<CompanyProfile> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string short_description = 3;
   */
  shortDescription = "";

  /**
   * @generated from field: string long_description = 4;
   */
  longDescription = "";

  /**
   * @generated from field: int32 founding_year = 5;
   */
  foundingYear = 0;

  /**
   * @generated from field: int32 total_funding = 6;
   */
  totalFunding = 0;

  /**
   * @generated from field: string industry_sector = 7;
   */
  industrySector = "";

  /**
   * @generated from field: string website = 8;
   */
  website = "";

  /**
   * @generated from field: string linkedin_url = 9;
   */
  linkedinUrl = "";

  /**
   * @generated from field: string company_size = 11;
   */
  companySize = "";

  /**
   * @generated from field: string paragraph_description = 12;
   */
  paragraphDescription = "";

  /**
   * stock market ticker if public
   *
   * @generated from field: string ticker = 13;
   */
  ticker = "";

  constructor(data?: PartialMessage<CompanyProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CompanyProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "long_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "founding_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "total_funding", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "industry_sector", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "linkedin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "company_size", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "paragraph_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "ticker", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyProfile {
    return new CompanyProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyProfile {
    return new CompanyProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyProfile {
    return new CompanyProfile().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyProfile | PlainMessage<CompanyProfile> | undefined, b: CompanyProfile | PlainMessage<CompanyProfile> | undefined): boolean {
    return proto3.util.equals(CompanyProfile, a, b);
  }
}

/**
 * @generated from message inventory.v1.PersonProfile
 */
export class PersonProfile extends Message<PersonProfile> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string linkedin_url = 2;
   */
  linkedinUrl = "";

  /**
   * @generated from field: string job_title = 3;
   */
  jobTitle = "";

  /**
   * @generated from field: google.protobuf.Duration job_duration = 4;
   */
  jobDuration?: Duration;

  /**
   * @generated from field: repeated inventory.v1.PersonWorkExperience work_experience = 5;
   */
  workExperience: PersonWorkExperience[] = [];

  constructor(data?: PartialMessage<PersonProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.PersonProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "linkedin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "job_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "job_duration", kind: "message", T: Duration },
    { no: 5, name: "work_experience", kind: "message", T: PersonWorkExperience, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PersonProfile {
    return new PersonProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PersonProfile {
    return new PersonProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PersonProfile {
    return new PersonProfile().fromJsonString(jsonString, options);
  }

  static equals(a: PersonProfile | PlainMessage<PersonProfile> | undefined, b: PersonProfile | PlainMessage<PersonProfile> | undefined): boolean {
    return proto3.util.equals(PersonProfile, a, b);
  }
}

/**
 * @generated from message inventory.v1.PersonWorkExperience
 */
export class PersonWorkExperience extends Message<PersonWorkExperience> {
  /**
   * @generated from field: string company_name = 1;
   */
  companyName = "";

  /**
   * @generated from field: string job_title = 2;
   */
  jobTitle = "";

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Duration duration = 5;
   */
  duration?: Duration;

  constructor(data?: PartialMessage<PersonWorkExperience>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.PersonWorkExperience";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "job_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_date", kind: "message", T: Timestamp },
    { no: 4, name: "end_date", kind: "message", T: Timestamp },
    { no: 5, name: "duration", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PersonWorkExperience {
    return new PersonWorkExperience().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PersonWorkExperience {
    return new PersonWorkExperience().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PersonWorkExperience {
    return new PersonWorkExperience().fromJsonString(jsonString, options);
  }

  static equals(a: PersonWorkExperience | PlainMessage<PersonWorkExperience> | undefined, b: PersonWorkExperience | PlainMessage<PersonWorkExperience> | undefined): boolean {
    return proto3.util.equals(PersonWorkExperience, a, b);
  }
}

/**
 * @generated from message inventory.v1.DataTypeInfo
 */
export class DataTypeInfo extends Message<DataTypeInfo> {
  /**
   * @generated from field: string data_type = 1;
   */
  dataType = "";

  /**
   * @generated from field: inventory.v1.DataTypeSource source = 2;
   */
  source = DataTypeSource.UNSPECIFIED;

  /**
   * used to link to a given finding that was created as a result of this problematic data access,
   * applicable only if source is UNDOCUMENTED_ACCESS (otherwise there would be no finding to link to)
   *
   * @generated from field: optional string finding_id = 3;
   */
  findingId?: string;

  constructor(data?: PartialMessage<DataTypeInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.DataTypeInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source", kind: "enum", T: proto3.getEnumType(DataTypeSource) },
    { no: 3, name: "finding_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataTypeInfo {
    return new DataTypeInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataTypeInfo {
    return new DataTypeInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataTypeInfo {
    return new DataTypeInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DataTypeInfo | PlainMessage<DataTypeInfo> | undefined, b: DataTypeInfo | PlainMessage<DataTypeInfo> | undefined): boolean {
    return proto3.util.equals(DataTypeInfo, a, b);
  }
}

/**
 * @generated from message inventory.v1.Asset
 */
export class Asset extends Message<Asset> {
  /**
   * @generated from field: string asset = 1;
   */
  asset = "";

  /**
   * @generated from field: string type = 2;
   */
  type = "";

  /**
   * @generated from field: repeated string tags = 3;
   */
  tags: string[] = [];

  /**
   * @generated from field: string details = 4;
   */
  details = "";

  /**
   * @generated from field: string country_name = 5;
   */
  countryName = "";

  /**
   * @generated from field: string country_code = 6;
   */
  countryCode = "";

  constructor(data?: PartialMessage<Asset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Asset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "asset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "country_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Asset {
    return new Asset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Asset {
    return new Asset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Asset {
    return new Asset().fromJsonString(jsonString, options);
  }

  static equals(a: Asset | PlainMessage<Asset> | undefined, b: Asset | PlainMessage<Asset> | undefined): boolean {
    return proto3.util.equals(Asset, a, b);
  }
}

/**
 * @generated from message inventory.v1.InherentRiskGroup
 */
export class InherentRiskGroup extends Message<InherentRiskGroup> {
  /**
   * category_enum should give enough information so category and subcategory are not needed
   * Didn't remove it yet, consider removing in the future
   *
   * @generated from field: string category = 1;
   */
  category = "";

  /**
   * @generated from field: optional string subcategory = 2;
   */
  subcategory?: string;

  /**
   * @generated from field: repeated inventory.v1.InherentRiskItem inherent_risk_items = 4;
   */
  inherentRiskItems: InherentRiskItem[] = [];

  /**
   * @generated from field: inventory.v1.InherentRiskCategoryEnum category_enum = 5;
   */
  categoryEnum = InherentRiskCategoryEnum.UNSPECIFIED;

  constructor(data?: PartialMessage<InherentRiskGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.InherentRiskGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "subcategory", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "inherent_risk_items", kind: "message", T: InherentRiskItem, repeated: true },
    { no: 5, name: "category_enum", kind: "enum", T: proto3.getEnumType(InherentRiskCategoryEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InherentRiskGroup {
    return new InherentRiskGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InherentRiskGroup {
    return new InherentRiskGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InherentRiskGroup {
    return new InherentRiskGroup().fromJsonString(jsonString, options);
  }

  static equals(a: InherentRiskGroup | PlainMessage<InherentRiskGroup> | undefined, b: InherentRiskGroup | PlainMessage<InherentRiskGroup> | undefined): boolean {
    return proto3.util.equals(InherentRiskGroup, a, b);
  }
}

/**
 * @generated from message inventory.v1.InherentRiskCategory
 */
export class InherentRiskCategory extends Message<InherentRiskCategory> {
  /**
   * @generated from field: inventory.v1.InherentRiskCategoryEnum category_enum = 1;
   */
  categoryEnum = InherentRiskCategoryEnum.UNSPECIFIED;

  /**
   * @generated from field: repeated inventory.v1.InherentRiskItem items = 2;
   */
  items: InherentRiskItem[] = [];

  constructor(data?: PartialMessage<InherentRiskCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.InherentRiskCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category_enum", kind: "enum", T: proto3.getEnumType(InherentRiskCategoryEnum) },
    { no: 2, name: "items", kind: "message", T: InherentRiskItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InherentRiskCategory {
    return new InherentRiskCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InherentRiskCategory {
    return new InherentRiskCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InherentRiskCategory {
    return new InherentRiskCategory().fromJsonString(jsonString, options);
  }

  static equals(a: InherentRiskCategory | PlainMessage<InherentRiskCategory> | undefined, b: InherentRiskCategory | PlainMessage<InherentRiskCategory> | undefined): boolean {
    return proto3.util.equals(InherentRiskCategory, a, b);
  }
}

/**
 * @generated from message inventory.v1.InherentRiskItem
 */
export class InherentRiskItem extends Message<InherentRiskItem> {
  /**
   * @generated from field: inventory.v1.RiskLevel severity = 1;
   */
  severity = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: inventory.v1.InherentRiskSource source = 3;
   */
  source = InherentRiskSource.UNSPECIFIED;

  /**
   * @generated from field: optional string id = 4;
   */
  id?: string;

  /**
   * @generated from field: optional inventory.v1.Source integration_source = 5;
   */
  integrationSource?: Source;

  /**
   * @generated from field: repeated inventory.v1.Permission permissions = 6;
   */
  permissions: Permission[] = [];

  /**
   * @generated from field: optional string explanation = 7;
   */
  explanation?: string;

  constructor(data?: PartialMessage<InherentRiskItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.InherentRiskItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "severity", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "source", kind: "enum", T: proto3.getEnumType(InherentRiskSource) },
    { no: 4, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "integration_source", kind: "message", T: Source, opt: true },
    { no: 6, name: "permissions", kind: "message", T: Permission, repeated: true },
    { no: 7, name: "explanation", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InherentRiskItem {
    return new InherentRiskItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InherentRiskItem {
    return new InherentRiskItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InherentRiskItem {
    return new InherentRiskItem().fromJsonString(jsonString, options);
  }

  static equals(a: InherentRiskItem | PlainMessage<InherentRiskItem> | undefined, b: InherentRiskItem | PlainMessage<InherentRiskItem> | undefined): boolean {
    return proto3.util.equals(InherentRiskItem, a, b);
  }
}

/**
 * @generated from message inventory.v1.CompanyActivityLogItem
 */
export class CompanyActivityLogItem extends Message<CompanyActivityLogItem> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp time = 2;
   */
  time?: Timestamp;

  /**
   * @generated from field: optional inventory.v1.Comment comment = 3;
   */
  comment?: Comment;

  /**
   * @generated from field: optional string user_id = 4;
   */
  userId?: string;

  /**
   * @generated from oneof inventory.v1.CompanyActivityLogItem.activity_log_item
   */
  activityLogItem: {
    /**
     * @generated from field: inventory.v1.CompanyRiskChange risk_change = 5;
     */
    value: CompanyRiskChange;
    case: "riskChange";
  } | {
    /**
     * @generated from field: inventory.v1.CompanyStatusChange status_change = 6;
     */
    value: CompanyStatusChange;
    case: "statusChange";
  } | {
    /**
     * @generated from field: inventory.v1.CompanyInherentRiskItemAdded inherent_risk_item_added = 7;
     */
    value: CompanyInherentRiskItemAdded;
    case: "inherentRiskItemAdded";
  } | {
    /**
     * @generated from field: inventory.v1.IRQUpdate irq_update = 8;
     */
    value: IRQUpdate;
    case: "irqUpdate";
  } | {
    /**
     * @generated from field: inventory.v1.FindingAdded finding_added = 9;
     */
    value: FindingAdded;
    case: "findingAdded";
  } | {
    /**
     * @generated from field: inventory.v1.FindingStatusChange finding_status_change = 10;
     */
    value: FindingStatusChange;
    case: "findingStatusChange";
  } | {
    /**
     * @generated from field: inventory.v1.FindingSeverityChange finding_severity_change = 11;
     */
    value: FindingSeverityChange;
    case: "findingSeverityChange";
  } | {
    /**
     * @generated from field: inventory.v1.ControlSettingsChange control_settings_change = 12;
     */
    value: ControlSettingsChange;
    case: "controlSettingsChange";
  } | {
    /**
     * @generated from field: inventory.v1.CompanyCreated company_created = 13;
     */
    value: CompanyCreated;
    case: "companyCreated";
  } | {
    /**
     * @generated from field: inventory.v1.CompanyBusinessOwnerChange business_owner_change = 14;
     */
    value: CompanyBusinessOwnerChange;
    case: "businessOwnerChange";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: optional inventory.v1.User user = 15;
   */
  user?: User;

  constructor(data?: PartialMessage<CompanyActivityLogItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CompanyActivityLogItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "time", kind: "message", T: Timestamp },
    { no: 3, name: "comment", kind: "message", T: Comment, opt: true },
    { no: 4, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "risk_change", kind: "message", T: CompanyRiskChange, oneof: "activity_log_item" },
    { no: 6, name: "status_change", kind: "message", T: CompanyStatusChange, oneof: "activity_log_item" },
    { no: 7, name: "inherent_risk_item_added", kind: "message", T: CompanyInherentRiskItemAdded, oneof: "activity_log_item" },
    { no: 8, name: "irq_update", kind: "message", T: IRQUpdate, oneof: "activity_log_item" },
    { no: 9, name: "finding_added", kind: "message", T: FindingAdded, oneof: "activity_log_item" },
    { no: 10, name: "finding_status_change", kind: "message", T: FindingStatusChange, oneof: "activity_log_item" },
    { no: 11, name: "finding_severity_change", kind: "message", T: FindingSeverityChange, oneof: "activity_log_item" },
    { no: 12, name: "control_settings_change", kind: "message", T: ControlSettingsChange, oneof: "activity_log_item" },
    { no: 13, name: "company_created", kind: "message", T: CompanyCreated, oneof: "activity_log_item" },
    { no: 14, name: "business_owner_change", kind: "message", T: CompanyBusinessOwnerChange, oneof: "activity_log_item" },
    { no: 15, name: "user", kind: "message", T: User, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyActivityLogItem {
    return new CompanyActivityLogItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyActivityLogItem {
    return new CompanyActivityLogItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyActivityLogItem {
    return new CompanyActivityLogItem().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyActivityLogItem | PlainMessage<CompanyActivityLogItem> | undefined, b: CompanyActivityLogItem | PlainMessage<CompanyActivityLogItem> | undefined): boolean {
    return proto3.util.equals(CompanyActivityLogItem, a, b);
  }
}

/**
 * @generated from message inventory.v1.CompanyBusinessOwnerChange
 */
export class CompanyBusinessOwnerChange extends Message<CompanyBusinessOwnerChange> {
  /**
   * @generated from field: optional inventory.v1.BusinessOwner prev_business_owner = 1;
   */
  prevBusinessOwner?: BusinessOwner;

  /**
   * @generated from field: optional inventory.v1.BusinessOwner new_business_owner = 2;
   */
  newBusinessOwner?: BusinessOwner;

  constructor(data?: PartialMessage<CompanyBusinessOwnerChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CompanyBusinessOwnerChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "prev_business_owner", kind: "message", T: BusinessOwner, opt: true },
    { no: 2, name: "new_business_owner", kind: "message", T: BusinessOwner, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyBusinessOwnerChange {
    return new CompanyBusinessOwnerChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyBusinessOwnerChange {
    return new CompanyBusinessOwnerChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyBusinessOwnerChange {
    return new CompanyBusinessOwnerChange().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyBusinessOwnerChange | PlainMessage<CompanyBusinessOwnerChange> | undefined, b: CompanyBusinessOwnerChange | PlainMessage<CompanyBusinessOwnerChange> | undefined): boolean {
    return proto3.util.equals(CompanyBusinessOwnerChange, a, b);
  }
}

/**
 * @generated from message inventory.v1.IRQUpdate
 */
export class IRQUpdate extends Message<IRQUpdate> {
  constructor(data?: PartialMessage<IRQUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.IRQUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IRQUpdate {
    return new IRQUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IRQUpdate {
    return new IRQUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IRQUpdate {
    return new IRQUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: IRQUpdate | PlainMessage<IRQUpdate> | undefined, b: IRQUpdate | PlainMessage<IRQUpdate> | undefined): boolean {
    return proto3.util.equals(IRQUpdate, a, b);
  }
}

/**
 * @generated from message inventory.v1.CompanyRiskChange
 */
export class CompanyRiskChange extends Message<CompanyRiskChange> {
  /**
   * @generated from field: inventory.v1.RiskLevel prev_inherent_risk = 2;
   */
  prevInherentRisk = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.RiskLevel new_inherent_risk = 3;
   */
  newInherentRisk = RiskLevel.UNSPECIFIED;

  constructor(data?: PartialMessage<CompanyRiskChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CompanyRiskChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "prev_inherent_risk", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 3, name: "new_inherent_risk", kind: "enum", T: proto3.getEnumType(RiskLevel) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyRiskChange {
    return new CompanyRiskChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyRiskChange {
    return new CompanyRiskChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyRiskChange {
    return new CompanyRiskChange().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyRiskChange | PlainMessage<CompanyRiskChange> | undefined, b: CompanyRiskChange | PlainMessage<CompanyRiskChange> | undefined): boolean {
    return proto3.util.equals(CompanyRiskChange, a, b);
  }
}

/**
 * @generated from message inventory.v1.CompanyStatusChange
 */
export class CompanyStatusChange extends Message<CompanyStatusChange> {
  /**
   * @generated from field: inventory.v1.CompanyStatus prev_status = 2;
   */
  prevStatus = CompanyStatus.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.CompanyStatus new_status = 3;
   */
  newStatus = CompanyStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<CompanyStatusChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CompanyStatusChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "prev_status", kind: "enum", T: proto3.getEnumType(CompanyStatus) },
    { no: 3, name: "new_status", kind: "enum", T: proto3.getEnumType(CompanyStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyStatusChange {
    return new CompanyStatusChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyStatusChange {
    return new CompanyStatusChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyStatusChange {
    return new CompanyStatusChange().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyStatusChange | PlainMessage<CompanyStatusChange> | undefined, b: CompanyStatusChange | PlainMessage<CompanyStatusChange> | undefined): boolean {
    return proto3.util.equals(CompanyStatusChange, a, b);
  }
}

/**
 * @generated from message inventory.v1.CompanyInherentRiskItemAdded
 */
export class CompanyInherentRiskItemAdded extends Message<CompanyInherentRiskItemAdded> {
  /**
   * @generated from field: inventory.v1.InherentRiskItem new_inherent_risk_item = 2;
   */
  newInherentRiskItem?: InherentRiskItem;

  /**
   * @generated from field: inventory.v1.InherentRiskCategoryEnum category_enum = 1;
   */
  categoryEnum = InherentRiskCategoryEnum.UNSPECIFIED;

  constructor(data?: PartialMessage<CompanyInherentRiskItemAdded>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CompanyInherentRiskItemAdded";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "new_inherent_risk_item", kind: "message", T: InherentRiskItem },
    { no: 1, name: "category_enum", kind: "enum", T: proto3.getEnumType(InherentRiskCategoryEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyInherentRiskItemAdded {
    return new CompanyInherentRiskItemAdded().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyInherentRiskItemAdded {
    return new CompanyInherentRiskItemAdded().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyInherentRiskItemAdded {
    return new CompanyInherentRiskItemAdded().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyInherentRiskItemAdded | PlainMessage<CompanyInherentRiskItemAdded> | undefined, b: CompanyInherentRiskItemAdded | PlainMessage<CompanyInherentRiskItemAdded> | undefined): boolean {
    return proto3.util.equals(CompanyInherentRiskItemAdded, a, b);
  }
}

/**
 * @generated from message inventory.v1.FindingAdded
 */
export class FindingAdded extends Message<FindingAdded> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: inventory.v1.RiskLevel risk_level = 2;
   */
  riskLevel = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: string id = 3;
   */
  id = "";

  constructor(data?: PartialMessage<FindingAdded>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FindingAdded";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindingAdded {
    return new FindingAdded().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindingAdded {
    return new FindingAdded().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindingAdded {
    return new FindingAdded().fromJsonString(jsonString, options);
  }

  static equals(a: FindingAdded | PlainMessage<FindingAdded> | undefined, b: FindingAdded | PlainMessage<FindingAdded> | undefined): boolean {
    return proto3.util.equals(FindingAdded, a, b);
  }
}

/**
 * @generated from message inventory.v1.ControlSettingsChange
 */
export class ControlSettingsChange extends Message<ControlSettingsChange> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: bool is_enabled = 2;
   */
  isEnabled = false;

  constructor(data?: PartialMessage<ControlSettingsChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ControlSettingsChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ControlSettingsChange {
    return new ControlSettingsChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ControlSettingsChange {
    return new ControlSettingsChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ControlSettingsChange {
    return new ControlSettingsChange().fromJsonString(jsonString, options);
  }

  static equals(a: ControlSettingsChange | PlainMessage<ControlSettingsChange> | undefined, b: ControlSettingsChange | PlainMessage<ControlSettingsChange> | undefined): boolean {
    return proto3.util.equals(ControlSettingsChange, a, b);
  }
}

/**
 * @generated from message inventory.v1.FindingStatusChange
 */
export class FindingStatusChange extends Message<FindingStatusChange> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: inventory.v1.FindingStatus prev_status = 2;
   */
  prevStatus = FindingStatus.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.FindingStatus new_status = 3;
   */
  newStatus = FindingStatus.UNSPECIFIED;

  /**
   * @generated from field: string finding_id = 4;
   */
  findingId = "";

  constructor(data?: PartialMessage<FindingStatusChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FindingStatusChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "prev_status", kind: "enum", T: proto3.getEnumType(FindingStatus) },
    { no: 3, name: "new_status", kind: "enum", T: proto3.getEnumType(FindingStatus) },
    { no: 4, name: "finding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindingStatusChange {
    return new FindingStatusChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindingStatusChange {
    return new FindingStatusChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindingStatusChange {
    return new FindingStatusChange().fromJsonString(jsonString, options);
  }

  static equals(a: FindingStatusChange | PlainMessage<FindingStatusChange> | undefined, b: FindingStatusChange | PlainMessage<FindingStatusChange> | undefined): boolean {
    return proto3.util.equals(FindingStatusChange, a, b);
  }
}

/**
 * @generated from message inventory.v1.FindingSeverityChange
 */
export class FindingSeverityChange extends Message<FindingSeverityChange> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: inventory.v1.RiskLevel prev_severity = 2;
   */
  prevSeverity = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.RiskLevel new_severity = 3;
   */
  newSeverity = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: string id = 4;
   */
  id = "";

  constructor(data?: PartialMessage<FindingSeverityChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FindingSeverityChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "prev_severity", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 3, name: "new_severity", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 4, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindingSeverityChange {
    return new FindingSeverityChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindingSeverityChange {
    return new FindingSeverityChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindingSeverityChange {
    return new FindingSeverityChange().fromJsonString(jsonString, options);
  }

  static equals(a: FindingSeverityChange | PlainMessage<FindingSeverityChange> | undefined, b: FindingSeverityChange | PlainMessage<FindingSeverityChange> | undefined): boolean {
    return proto3.util.equals(FindingSeverityChange, a, b);
  }
}

/**
 * @generated from message inventory.v1.CompanyCreated
 */
export class CompanyCreated extends Message<CompanyCreated> {
  constructor(data?: PartialMessage<CompanyCreated>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CompanyCreated";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyCreated {
    return new CompanyCreated().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyCreated {
    return new CompanyCreated().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyCreated {
    return new CompanyCreated().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyCreated | PlainMessage<CompanyCreated> | undefined, b: CompanyCreated | PlainMessage<CompanyCreated> | undefined): boolean {
    return proto3.util.equals(CompanyCreated, a, b);
  }
}

/**
 * @generated from message inventory.v1.Source
 */
export class Source extends Message<Source> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string img_url = 2;
   */
  imgUrl = "";

  constructor(data?: PartialMessage<Source>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Source";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "img_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Source {
    return new Source().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Source {
    return new Source().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Source {
    return new Source().fromJsonString(jsonString, options);
  }

  static equals(a: Source | PlainMessage<Source> | undefined, b: Source | PlainMessage<Source> | undefined): boolean {
    return proto3.util.equals(Source, a, b);
  }
}

/**
 * @generated from message inventory.v1.Bookmark
 */
export class Bookmark extends Message<Bookmark> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string company_id = 2;
   */
  companyId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string url = 4;
   */
  url = "";

  /**
   * @generated from field: string favicon_url = 5;
   */
  faviconUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Bookmark>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Bookmark";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "favicon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Bookmark {
    return new Bookmark().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Bookmark {
    return new Bookmark().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Bookmark {
    return new Bookmark().fromJsonString(jsonString, options);
  }

  static equals(a: Bookmark | PlainMessage<Bookmark> | undefined, b: Bookmark | PlainMessage<Bookmark> | undefined): boolean {
    return proto3.util.equals(Bookmark, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetBookmarksRequest
 */
export class GetBookmarksRequest extends Message<GetBookmarksRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<GetBookmarksRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetBookmarksRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBookmarksRequest {
    return new GetBookmarksRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBookmarksRequest {
    return new GetBookmarksRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBookmarksRequest {
    return new GetBookmarksRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetBookmarksRequest | PlainMessage<GetBookmarksRequest> | undefined, b: GetBookmarksRequest | PlainMessage<GetBookmarksRequest> | undefined): boolean {
    return proto3.util.equals(GetBookmarksRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetBookmarksResponse
 */
export class GetBookmarksResponse extends Message<GetBookmarksResponse> {
  /**
   * @generated from field: repeated inventory.v1.Bookmark bookmarks = 1;
   */
  bookmarks: Bookmark[] = [];

  constructor(data?: PartialMessage<GetBookmarksResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetBookmarksResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bookmarks", kind: "message", T: Bookmark, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBookmarksResponse {
    return new GetBookmarksResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBookmarksResponse {
    return new GetBookmarksResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBookmarksResponse {
    return new GetBookmarksResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetBookmarksResponse | PlainMessage<GetBookmarksResponse> | undefined, b: GetBookmarksResponse | PlainMessage<GetBookmarksResponse> | undefined): boolean {
    return proto3.util.equals(GetBookmarksResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.AddBookmarkRequest
 */
export class AddBookmarkRequest extends Message<AddBookmarkRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  constructor(data?: PartialMessage<AddBookmarkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AddBookmarkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddBookmarkRequest {
    return new AddBookmarkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddBookmarkRequest {
    return new AddBookmarkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddBookmarkRequest {
    return new AddBookmarkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddBookmarkRequest | PlainMessage<AddBookmarkRequest> | undefined, b: AddBookmarkRequest | PlainMessage<AddBookmarkRequest> | undefined): boolean {
    return proto3.util.equals(AddBookmarkRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.AddBookmarkResponse
 */
export class AddBookmarkResponse extends Message<AddBookmarkResponse> {
  constructor(data?: PartialMessage<AddBookmarkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AddBookmarkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddBookmarkResponse {
    return new AddBookmarkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddBookmarkResponse {
    return new AddBookmarkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddBookmarkResponse {
    return new AddBookmarkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddBookmarkResponse | PlainMessage<AddBookmarkResponse> | undefined, b: AddBookmarkResponse | PlainMessage<AddBookmarkResponse> | undefined): boolean {
    return proto3.util.equals(AddBookmarkResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateBookmarkRequest
 */
export class UpdateBookmarkRequest extends Message<UpdateBookmarkRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  constructor(data?: PartialMessage<UpdateBookmarkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateBookmarkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBookmarkRequest {
    return new UpdateBookmarkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBookmarkRequest {
    return new UpdateBookmarkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBookmarkRequest {
    return new UpdateBookmarkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBookmarkRequest | PlainMessage<UpdateBookmarkRequest> | undefined, b: UpdateBookmarkRequest | PlainMessage<UpdateBookmarkRequest> | undefined): boolean {
    return proto3.util.equals(UpdateBookmarkRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateBookmarkResponse
 */
export class UpdateBookmarkResponse extends Message<UpdateBookmarkResponse> {
  constructor(data?: PartialMessage<UpdateBookmarkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateBookmarkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBookmarkResponse {
    return new UpdateBookmarkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBookmarkResponse {
    return new UpdateBookmarkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBookmarkResponse {
    return new UpdateBookmarkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBookmarkResponse | PlainMessage<UpdateBookmarkResponse> | undefined, b: UpdateBookmarkResponse | PlainMessage<UpdateBookmarkResponse> | undefined): boolean {
    return proto3.util.equals(UpdateBookmarkResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.DeleteBookmarkRequest
 */
export class DeleteBookmarkRequest extends Message<DeleteBookmarkRequest> {
  /**
   * @generated from field: string bookmark_id = 1;
   */
  bookmarkId = "";

  constructor(data?: PartialMessage<DeleteBookmarkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.DeleteBookmarkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bookmark_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteBookmarkRequest {
    return new DeleteBookmarkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteBookmarkRequest {
    return new DeleteBookmarkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteBookmarkRequest {
    return new DeleteBookmarkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteBookmarkRequest | PlainMessage<DeleteBookmarkRequest> | undefined, b: DeleteBookmarkRequest | PlainMessage<DeleteBookmarkRequest> | undefined): boolean {
    return proto3.util.equals(DeleteBookmarkRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.DeleteBookmarkResponse
 */
export class DeleteBookmarkResponse extends Message<DeleteBookmarkResponse> {
  constructor(data?: PartialMessage<DeleteBookmarkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.DeleteBookmarkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteBookmarkResponse {
    return new DeleteBookmarkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteBookmarkResponse {
    return new DeleteBookmarkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteBookmarkResponse {
    return new DeleteBookmarkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteBookmarkResponse | PlainMessage<DeleteBookmarkResponse> | undefined, b: DeleteBookmarkResponse | PlainMessage<DeleteBookmarkResponse> | undefined): boolean {
    return proto3.util.equals(DeleteBookmarkResponse, a, b);
  }
}

