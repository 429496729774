import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { TooltipContentProps } from '@radix-ui/react-tooltip'
import * as React from 'react'

import { cn } from '@/lib/style-helpers'

const TooltipProvider = TooltipPrimitive.Provider

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-sm border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

interface TooltipProps extends TooltipContentProps {
  trigger: React.ReactNode
  tooltipDisabled?: boolean
  triggerClassName?: string
  onOpenChange?: (open: boolean) => void
  open?: boolean
}

const Tooltip = ({
  className,
  triggerClassName,
  children,
  trigger,
  tooltipDisabled,
  onOpenChange,
  open,
  ...props
}: TooltipProps) => {
  return tooltipDisabled ? (
    trigger
  ) : (
    <TooltipProvider>
      <TooltipPrimitive.Root
        onOpenChange={onOpenChange}
        delayDuration={100}
        defaultOpen={open || false}
      >
        <TooltipTrigger className={cn('flex', triggerClassName)}>{trigger}</TooltipTrigger>
        {children && (
          <TooltipContent {...props} className={cn('rounded p-2 text-gray-700', className)}>
            {children}
          </TooltipContent>
        )}
      </TooltipPrimitive.Root>
    </TooltipProvider>
  )
}

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger }
