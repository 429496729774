import { ComponentProps, ReactNode, useEffect, useState } from 'react'

import { Switch } from '@/components/ui/switch'

type OptimisticSwitch = {
  disabled?: boolean
  isError: boolean
  isActive: boolean
  setIsActive: (isActive: boolean) => Promise<void>
  switchComponent?: {
    checked: ReactNode
    unchecked: ReactNode
  }
} & Omit<ComponentProps<typeof Switch>, 'checked'>

export const OptimisticSwitch = ({
  setIsActive,
  isError,
  isActive,
  disabled,
  switchComponent,
  ...props
}: OptimisticSwitch) => {
  const [isOptimisticActive, setIsOptimisticActive] = useState(isActive)

  useEffect(() => {
    // To make sure that the isOptimisticActive is up to date with the server
    setIsOptimisticActive(isActive)
  }, [isActive, isError])

  const handleToggle = async () => {
    // To show immediate change
    setIsOptimisticActive(!isOptimisticActive)
    await setIsActive(!isOptimisticActive)
  }

  if (switchComponent) {
    return (
      <div
        onClick={(event) => {
          event.stopPropagation()
          handleToggle()
        }}
      >
        {isOptimisticActive ? switchComponent.checked : switchComponent.unchecked}
      </div>
    )
  }
  return (
    <Switch
      {...props}
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation()
        handleToggle()
      }}
      checked={isOptimisticActive}
    />
  )
}
