import { Upload as AntUpload, UploadProps } from 'antd'
import { InboxIcon, UploadIcon } from 'lucide-react'
import pluralize from 'pluralize'

import { cn } from '@/lib/style-helpers'

import { Button } from '@/components/ui/button'

import './ant-upload.less'

const { Dragger } = AntUpload
export const Upload = (props: UploadProps) => {
  return (
    <Dragger {...props}>
      <p className='ant-upload-drag-icon flex justify-around'>
        <InboxIcon
          size={40}
          strokeWidth={1}
          className={cn('text-purple-600', props.disabled && 'text-opacity-50')}
        />
      </p>
      <Button disabled={props.disabled} className='mb-3'>
        <UploadIcon size={14} className='mr-2' />
        Select {pluralize('File', props.maxCount || 0)}
      </Button>
      <p>Drag file{props.maxCount !== 1 && '(s)'} to this area to upload</p>
    </Dragger>
  )
}
