// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file inventory/v1/control_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCommentRequest, AddCommentResponse, GetConifgurableFactTypesRequest, GetConifgurableFactTypesResponse, GetControlRequest, GetControlResponse, GetControlTimelineRequest, GetControlTimelineResponse, ListControlSettingsRequest, ListControlSettingsResponse, ListControlsRequest, ListControlsResponse, UpdateControlRequest, UpdateControlResponse, UpdateControlSettingsRequest, UpdateControlSettingsResponse } from "./control_service_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "inventory.v1.ControlInventoryService";

/**
 * @generated from service inventory.v1.ControlInventoryService
 */
export const ControlInventoryService = {
  typeName: "inventory.v1.ControlInventoryService",
  methods: {
    /**
     * @generated from rpc inventory.v1.ControlInventoryService.AddComment
     */
    addComment: {
      name: "AddComment",
      I: AddCommentRequest,
      O: AddCommentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.ControlInventoryService.ListControlSettings
     */
    listControlSettings: {
      name: "ListControlSettings",
      I: ListControlSettingsRequest,
      O: ListControlSettingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.ControlInventoryService.UpdateControlSettings
     */
    updateControlSettings: {
      name: "UpdateControlSettings",
      I: UpdateControlSettingsRequest,
      O: UpdateControlSettingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.ControlInventoryService.ListControls
     */
    listControls: {
      name: "ListControls",
      I: ListControlsRequest,
      O: ListControlsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.ControlInventoryService.GetControl
     */
    getControl: {
      name: "GetControl",
      I: GetControlRequest,
      O: GetControlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.ControlInventoryService.UpdateControl
     */
    updateControl: {
      name: "UpdateControl",
      I: UpdateControlRequest,
      O: UpdateControlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.ControlInventoryService.GetConifgurableFactTypes
     */
    getConifgurableFactTypes: {
      name: "GetConifgurableFactTypes",
      I: GetConifgurableFactTypesRequest,
      O: GetConifgurableFactTypesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.ControlInventoryService.GetControlTimeline
     */
    getControlTimeline: {
      name: "GetControlTimeline",
      I: GetControlTimelineRequest,
      O: GetControlTimelineResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

const $queryService = createQueryService({  service: ControlInventoryService,});

/**
 * @generated from rpc inventory.v1.ControlInventoryService.AddComment
 */
export const addComment: UnaryFunctionsWithHooks<AddCommentRequest, AddCommentResponse> = {   ...$queryService.addComment,  ...createUnaryHooks($queryService.addComment)};

/**
 * @generated from rpc inventory.v1.ControlInventoryService.ListControlSettings
 */
export const listControlSettings: UnaryFunctionsWithHooks<ListControlSettingsRequest, ListControlSettingsResponse> = {   ...$queryService.listControlSettings,  ...createUnaryHooks($queryService.listControlSettings)};

/**
 * @generated from rpc inventory.v1.ControlInventoryService.UpdateControlSettings
 */
export const updateControlSettings: UnaryFunctionsWithHooks<UpdateControlSettingsRequest, UpdateControlSettingsResponse> = {   ...$queryService.updateControlSettings,  ...createUnaryHooks($queryService.updateControlSettings)};

/**
 * @generated from rpc inventory.v1.ControlInventoryService.ListControls
 */
export const listControls: UnaryFunctionsWithHooks<ListControlsRequest, ListControlsResponse> = {   ...$queryService.listControls,  ...createUnaryHooks($queryService.listControls)};

/**
 * @generated from rpc inventory.v1.ControlInventoryService.GetControl
 */
export const getControl: UnaryFunctionsWithHooks<GetControlRequest, GetControlResponse> = {   ...$queryService.getControl,  ...createUnaryHooks($queryService.getControl)};

/**
 * @generated from rpc inventory.v1.ControlInventoryService.UpdateControl
 */
export const updateControl: UnaryFunctionsWithHooks<UpdateControlRequest, UpdateControlResponse> = {   ...$queryService.updateControl,  ...createUnaryHooks($queryService.updateControl)};

/**
 * @generated from rpc inventory.v1.ControlInventoryService.GetConifgurableFactTypes
 */
export const getConifgurableFactTypes: UnaryFunctionsWithHooks<GetConifgurableFactTypesRequest, GetConifgurableFactTypesResponse> = {   ...$queryService.getConifgurableFactTypes,  ...createUnaryHooks($queryService.getConifgurableFactTypes)};

/**
 * @generated from rpc inventory.v1.ControlInventoryService.GetControlTimeline
 */
export const getControlTimeline: UnaryFunctionsWithHooks<GetControlTimelineRequest, GetControlTimelineResponse> = {   ...$queryService.getControlTimeline,  ...createUnaryHooks($queryService.getControlTimeline)};
