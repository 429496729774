import { InfoIcon, PlusIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { useListQuestionnaireTemplates, useUpdateTemplate } from '@/api/questionnaire-template.hook'
import {
  QuestionnaireTemplate,
  QuestionnaireTemplateSection,
} from '@/gen/questionnaire/v1/template_service_pb'

import { useReportErrorsCallback } from '@/lib/error-reporting'

import { Loading } from '@/components/loading'
import { OptimisticSwitch } from '@/components/optimistic-backend-toggler'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Tooltip } from '@/components/ui/tooltip'

import { useImportTemplateModal } from './use-import-template-modal'

export const TemplatesSection = () => {
  const { showModal, renderModal } = useImportTemplateModal()
  return (
    <div>
      <div className='mb-5 flex justify-between'>
        <h2 className='text-3xl font-bold'>Questionnaire Templates</h2>
        <Button onClick={showModal} className='flex items-center gap-2 px-4 py-2'>
          <PlusIcon size={14} />
          <span>Import Template</span>
        </Button>
      </div>
      <TemplatesTable />
      {renderModal()}
    </div>
  )
}

const TemplatesTable = () => {
  const navigate = useNavigate()
  const { mutateAsync: updateTemplate, isError: isUpdateTemplateError } = useUpdateTemplate()

  const { data: templates, isLoading, error } = useListQuestionnaireTemplates()
  const reportErr = useReportErrorsCallback()

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    reportErr(error)
    return null
  }

  const columns: ColumnType<QuestionnaireTemplate>[] = [
    {
      title: 'Template',
      render: ({ name, description }) => (
        <>
          <div>{name}</div>
          {description && <div className='text-sm text-gray-400'>{description}</div>}
        </>
      ),
      defaultSortOrder: 'ascend',
      accessor: ({ name, description }) => ({
        searchValue: `${name} ${description}`,
        sortValue: name,
      }),
    },
    {
      title: 'Questions',
      dataIndex: 'questionCount',
    },
    {
      title: 'Sections',
      dataIndex: 'sections',
      render: (sections: QuestionnaireTemplateSection[]) => <SectionsList sections={sections} />,
    },
    {
      sort: false,
      title: (
        <TextWithIcon
          text='Default'
          icon={
            <Tooltip trigger={<InfoIcon size={14} />}>
              <div className='text-center'>
                Use default questionnaires for questionnaires
                <br />
                you want to send by default each time
                <br /> you reach out to third-parties
              </div>
            </Tooltip>
          }
          iconPosition='end'
        />
      ),
      dataIndex: 'isDefault',
      render: (isDefault: boolean, { id }: QuestionnaireTemplate) => (
        <OptimisticSwitch
          isError={isUpdateTemplateError}
          isActive={isDefault}
          setIsActive={async (value) => {
            await updateTemplate({ templateId: id, isDefault: value })
          }}
        />
      ),
    },
  ]

  const onRow = ({ id }: QuestionnaireTemplate) => {
    return { onClick: () => navigate(`/questionnaires/templates/${id}`) }
  }

  return <Table onRow={onRow} showFilterBar={false} columns={columns} dataSource={templates} />
}

const SectionsList = ({ sections }: { sections: QuestionnaireTemplateSection[] }) => {
  const maxDisplay = 3
  const hiddenCount = sections.length - maxDisplay
  const visibleSections = sections.slice(0, maxDisplay)

  return (
    <div className='flex cursor-default flex-wrap gap-1'>
      {visibleSections.map((s, i) => (
        <Badge className='bg-white text-sm' key={i} variant={'outline'}>
          {s.name}
        </Badge>
      ))}
      {hiddenCount > 0 && (
        <Badge className='bg-white text-sm' variant={'outline'}>
          {`+${hiddenCount}`}
        </Badge>
      )}
    </div>
  )
}
