// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/assessment_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { RiskLevel } from "./risk_pb.js";
import { User } from "../../customer/management/v1/service_pb.js";
import { FindingCountStat, InherentRiskGroup } from "./company_service_pb.js";
import { ControlCountStat, GetControlResponse, GetControlTimelineResponse } from "./control_service_pb.js";
import { Artifact } from "./artifact_service_pb.js";
import { GetFindingResponse, GetFindingTimelineResponse } from "./finding_service_pb.js";

/**
 * @generated from enum inventory.v1.AssessmentDecision
 */
export enum AssessmentDecision {
  /**
   * @generated from enum value: ASSESSMENT_DECISION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ASSESSMENT_DECISION_APPROVED = 1;
   */
  APPROVED = 1,

  /**
   * @generated from enum value: ASSESSMENT_DECISION_REJECTED = 2;
   */
  REJECTED = 2,

  /**
   * @generated from enum value: ASSESSMENT_DECISION_CONDITIONALLY_APPROVED = 3;
   */
  CONDITIONALLY_APPROVED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AssessmentDecision)
proto3.util.setEnumType(AssessmentDecision, "inventory.v1.AssessmentDecision", [
  { no: 0, name: "ASSESSMENT_DECISION_UNSPECIFIED" },
  { no: 1, name: "ASSESSMENT_DECISION_APPROVED" },
  { no: 2, name: "ASSESSMENT_DECISION_REJECTED" },
  { no: 3, name: "ASSESSMENT_DECISION_CONDITIONALLY_APPROVED" },
]);

/**
 * @generated from message inventory.v1.FinalizeAssessmentRequest
 */
export class FinalizeAssessmentRequest extends Message<FinalizeAssessmentRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: inventory.v1.AssessmentDecision decision = 2;
   */
  decision = AssessmentDecision.UNSPECIFIED;

  /**
   * @generated from field: optional string comment = 3;
   */
  comment?: string;

  constructor(data?: PartialMessage<FinalizeAssessmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FinalizeAssessmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "decision", kind: "enum", T: proto3.getEnumType(AssessmentDecision) },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinalizeAssessmentRequest {
    return new FinalizeAssessmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinalizeAssessmentRequest {
    return new FinalizeAssessmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinalizeAssessmentRequest {
    return new FinalizeAssessmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FinalizeAssessmentRequest | PlainMessage<FinalizeAssessmentRequest> | undefined, b: FinalizeAssessmentRequest | PlainMessage<FinalizeAssessmentRequest> | undefined): boolean {
    return proto3.util.equals(FinalizeAssessmentRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.FinalizeAssessmentResponse
 */
export class FinalizeAssessmentResponse extends Message<FinalizeAssessmentResponse> {
  constructor(data?: PartialMessage<FinalizeAssessmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FinalizeAssessmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinalizeAssessmentResponse {
    return new FinalizeAssessmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinalizeAssessmentResponse {
    return new FinalizeAssessmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinalizeAssessmentResponse {
    return new FinalizeAssessmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FinalizeAssessmentResponse | PlainMessage<FinalizeAssessmentResponse> | undefined, b: FinalizeAssessmentResponse | PlainMessage<FinalizeAssessmentResponse> | undefined): boolean {
    return proto3.util.equals(FinalizeAssessmentResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListAssessmentsRequest
 */
export class ListAssessmentsRequest extends Message<ListAssessmentsRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<ListAssessmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListAssessmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssessmentsRequest {
    return new ListAssessmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssessmentsRequest {
    return new ListAssessmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssessmentsRequest {
    return new ListAssessmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssessmentsRequest | PlainMessage<ListAssessmentsRequest> | undefined, b: ListAssessmentsRequest | PlainMessage<ListAssessmentsRequest> | undefined): boolean {
    return proto3.util.equals(ListAssessmentsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListAssessmentsResponse
 */
export class ListAssessmentsResponse extends Message<ListAssessmentsResponse> {
  /**
   * @generated from field: repeated inventory.v1.AssessmentInfo assessments = 1;
   */
  assessments: AssessmentInfo[] = [];

  constructor(data?: PartialMessage<ListAssessmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListAssessmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessments", kind: "message", T: AssessmentInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssessmentsResponse {
    return new ListAssessmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssessmentsResponse {
    return new ListAssessmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssessmentsResponse {
    return new ListAssessmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssessmentsResponse | PlainMessage<ListAssessmentsResponse> | undefined, b: ListAssessmentsResponse | PlainMessage<ListAssessmentsResponse> | undefined): boolean {
    return proto3.util.equals(ListAssessmentsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetAssessmentRequest
 */
export class GetAssessmentRequest extends Message<GetAssessmentRequest> {
  /**
   * @generated from field: string assessment_id = 1;
   */
  assessmentId = "";

  constructor(data?: PartialMessage<GetAssessmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetAssessmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentRequest {
    return new GetAssessmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentRequest {
    return new GetAssessmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentRequest {
    return new GetAssessmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentRequest | PlainMessage<GetAssessmentRequest> | undefined, b: GetAssessmentRequest | PlainMessage<GetAssessmentRequest> | undefined): boolean {
    return proto3.util.equals(GetAssessmentRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetAssessmentResponse
 */
export class GetAssessmentResponse extends Message<GetAssessmentResponse> {
  /**
   * @generated from field: inventory.v1.Assessment assessment = 1;
   */
  assessment?: Assessment;

  constructor(data?: PartialMessage<GetAssessmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetAssessmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment", kind: "message", T: Assessment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentResponse {
    return new GetAssessmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentResponse {
    return new GetAssessmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentResponse {
    return new GetAssessmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentResponse | PlainMessage<GetAssessmentResponse> | undefined, b: GetAssessmentResponse | PlainMessage<GetAssessmentResponse> | undefined): boolean {
    return proto3.util.equals(GetAssessmentResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.AssessmentInfo
 */
export class AssessmentInfo extends Message<AssessmentInfo> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  /**
   * @generated from field: inventory.v1.RiskLevel risk_level = 3;
   */
  riskLevel = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: inventory.v1.AssessmentDecision decision = 5;
   */
  decision = AssessmentDecision.UNSPECIFIED;

  /**
   * @generated from field: repeated customer.management.v1.User authors = 6;
   */
  authors: User[] = [];

  /**
   * @generated from field: repeated inventory.v1.FindingCountStat findings_by_level = 7;
   */
  findingsByLevel: FindingCountStat[] = [];

  /**
   * @generated from field: repeated inventory.v1.ControlCountStat controls_by_status = 8;
   */
  controlsByStatus: ControlCountStat[] = [];

  /**
   * @generated from field: string company_id = 9;
   */
  companyId = "";

  /**
   * @generated from field: bytes notes_ydoc = 10;
   */
  notesYdoc = new Uint8Array(0);

  constructor(data?: PartialMessage<AssessmentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AssessmentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "decision", kind: "enum", T: proto3.getEnumType(AssessmentDecision) },
    { no: 6, name: "authors", kind: "message", T: User, repeated: true },
    { no: 7, name: "findings_by_level", kind: "message", T: FindingCountStat, repeated: true },
    { no: 8, name: "controls_by_status", kind: "message", T: ControlCountStat, repeated: true },
    { no: 9, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "notes_ydoc", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentInfo {
    return new AssessmentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentInfo {
    return new AssessmentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentInfo {
    return new AssessmentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentInfo | PlainMessage<AssessmentInfo> | undefined, b: AssessmentInfo | PlainMessage<AssessmentInfo> | undefined): boolean {
    return proto3.util.equals(AssessmentInfo, a, b);
  }
}

/**
 * @generated from message inventory.v1.Assessment
 */
export class Assessment extends Message<Assessment> {
  /**
   * @generated from field: inventory.v1.AssessmentInfo info = 1;
   */
  info?: AssessmentInfo;

  /**
   * @generated from field: repeated inventory.v1.AssessmentFinding assessment_findings = 2;
   */
  assessmentFindings: AssessmentFinding[] = [];

  /**
   * @generated from field: repeated inventory.v1.AssessmentControl assessment_controls = 3;
   */
  assessmentControls: AssessmentControl[] = [];

  /**
   * @generated from field: repeated inventory.v1.Artifact artifacts = 4;
   */
  artifacts: Artifact[] = [];

  /**
   * @generated from field: repeated inventory.v1.InherentRiskGroup inherent_risk_groups = 5;
   */
  inherentRiskGroups: InherentRiskGroup[] = [];

  constructor(data?: PartialMessage<Assessment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Assessment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "info", kind: "message", T: AssessmentInfo },
    { no: 2, name: "assessment_findings", kind: "message", T: AssessmentFinding, repeated: true },
    { no: 3, name: "assessment_controls", kind: "message", T: AssessmentControl, repeated: true },
    { no: 4, name: "artifacts", kind: "message", T: Artifact, repeated: true },
    { no: 5, name: "inherent_risk_groups", kind: "message", T: InherentRiskGroup, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Assessment {
    return new Assessment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Assessment {
    return new Assessment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Assessment {
    return new Assessment().fromJsonString(jsonString, options);
  }

  static equals(a: Assessment | PlainMessage<Assessment> | undefined, b: Assessment | PlainMessage<Assessment> | undefined): boolean {
    return proto3.util.equals(Assessment, a, b);
  }
}

/**
 * @generated from message inventory.v1.AssessmentFinding
 */
export class AssessmentFinding extends Message<AssessmentFinding> {
  /**
   * @generated from field: inventory.v1.GetFindingResponse finding = 1;
   */
  finding?: GetFindingResponse;

  /**
   * @generated from field: inventory.v1.GetFindingTimelineResponse timeline = 2;
   */
  timeline?: GetFindingTimelineResponse;

  constructor(data?: PartialMessage<AssessmentFinding>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AssessmentFinding";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "finding", kind: "message", T: GetFindingResponse },
    { no: 2, name: "timeline", kind: "message", T: GetFindingTimelineResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentFinding {
    return new AssessmentFinding().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentFinding {
    return new AssessmentFinding().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentFinding {
    return new AssessmentFinding().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentFinding | PlainMessage<AssessmentFinding> | undefined, b: AssessmentFinding | PlainMessage<AssessmentFinding> | undefined): boolean {
    return proto3.util.equals(AssessmentFinding, a, b);
  }
}

/**
 * @generated from message inventory.v1.AssessmentControl
 */
export class AssessmentControl extends Message<AssessmentControl> {
  /**
   * @generated from field: inventory.v1.GetControlResponse control = 1;
   */
  control?: GetControlResponse;

  /**
   * @generated from field: inventory.v1.GetControlTimelineResponse timeline = 2;
   */
  timeline?: GetControlTimelineResponse;

  constructor(data?: PartialMessage<AssessmentControl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AssessmentControl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control", kind: "message", T: GetControlResponse },
    { no: 2, name: "timeline", kind: "message", T: GetControlTimelineResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentControl {
    return new AssessmentControl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentControl {
    return new AssessmentControl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentControl {
    return new AssessmentControl().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentControl | PlainMessage<AssessmentControl> | undefined, b: AssessmentControl | PlainMessage<AssessmentControl> | undefined): boolean {
    return proto3.util.equals(AssessmentControl, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetRoomByCompanyIdRequest
 */
export class GetRoomByCompanyIdRequest extends Message<GetRoomByCompanyIdRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<GetRoomByCompanyIdRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetRoomByCompanyIdRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomByCompanyIdRequest {
    return new GetRoomByCompanyIdRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomByCompanyIdRequest {
    return new GetRoomByCompanyIdRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomByCompanyIdRequest {
    return new GetRoomByCompanyIdRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomByCompanyIdRequest | PlainMessage<GetRoomByCompanyIdRequest> | undefined, b: GetRoomByCompanyIdRequest | PlainMessage<GetRoomByCompanyIdRequest> | undefined): boolean {
    return proto3.util.equals(GetRoomByCompanyIdRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetRoomByCompanyIdResponse
 */
export class GetRoomByCompanyIdResponse extends Message<GetRoomByCompanyIdResponse> {
  /**
   * @generated from field: string room_id = 1;
   */
  roomId = "";

  constructor(data?: PartialMessage<GetRoomByCompanyIdResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetRoomByCompanyIdResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomByCompanyIdResponse {
    return new GetRoomByCompanyIdResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomByCompanyIdResponse {
    return new GetRoomByCompanyIdResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomByCompanyIdResponse {
    return new GetRoomByCompanyIdResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomByCompanyIdResponse | PlainMessage<GetRoomByCompanyIdResponse> | undefined, b: GetRoomByCompanyIdResponse | PlainMessage<GetRoomByCompanyIdResponse> | undefined): boolean {
    return proto3.util.equals(GetRoomByCompanyIdResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetTokenRequest
 */
export class GetTokenRequest extends Message<GetTokenRequest> {
  constructor(data?: PartialMessage<GetTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTokenRequest {
    return new GetTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTokenRequest {
    return new GetTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTokenRequest {
    return new GetTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTokenRequest | PlainMessage<GetTokenRequest> | undefined, b: GetTokenRequest | PlainMessage<GetTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetTokenRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetTokenResponse
 */
export class GetTokenResponse extends Message<GetTokenResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<GetTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTokenResponse {
    return new GetTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTokenResponse {
    return new GetTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTokenResponse {
    return new GetTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTokenResponse | PlainMessage<GetTokenResponse> | undefined, b: GetTokenResponse | PlainMessage<GetTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetTokenResponse, a, b);
  }
}

