import { Duration, PlainMessage, Timestamp } from '@bufbuild/protobuf'
import { format } from 'date-fns'
import humanizeDuration from 'humanize-duration'
import pluralize from 'pluralize'

export const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24

const dateFormats = {
  date: "dd MMM ''yy",
  dateTime: 'dd MMM yyyy, HH:mm',
  shortDateTime: "d MMM ''yy",
  dateWithoutYear: 'dd MMM',
  longDate: 'EEEE d MMMM, yyyy, HH:mm',
  longDateWithSeconds: 'EEEE d MMMM, yyyy, HH:mm:ss',
  monthOnly: 'MMMM',
  monthWithYear: 'MMMM yyyy',
}

export const formatDate = (date: Date, formatType: keyof typeof dateFormats = 'date') => {
  return format(date, dateFormats[formatType])
}

export const formatMonth = (date: Date) => {
  const currentYear = new Date().getFullYear()
  const dateYear = date.getFullYear()
  const formatType = currentYear === dateYear ? 'monthOnly' : 'monthWithYear'
  return formatDate(date, formatType)
}

export const timeSince = (date: Date) => {
  const now = new Date()
  const diffInMillis = now.getTime() - date.getTime()
  return timeSinceMilliseconds(Math.abs(diffInMillis))
}

export const timeSinceMilliseconds = (ms: number) => {
  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days > 0) {
    return { value: days, unit: pluralize('day', days) }
  }

  if (hours > 0) {
    return { value: hours, unit: pluralize('hour', hours) }
  }

  if (minutes > 0) {
    return { value: minutes, unit: pluralize('minute', minutes) }
  }

  return { value: seconds, unit: pluralize('second', seconds) }
}

const calculateTimeDiff = (time: Date) => {
  const now = new Date()
  return time.getTime() - now.getTime()
}

export const relativeTimeFormatted = (time: Date) => {
  const timeDiff = calculateTimeDiff(time)
  const { unit, value } = timeSince(time)
  if (timeDiff > 0) {
    return `${value} ${unit} left`
  }
  return `${value} ${unit} ago`
}

export const getTimestampDaysAgo = (daysAgo: number) => {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() - daysAgo)

  return Timestamp.fromDate(currentDate)
}

export const protoDurationToString = (d?: PlainMessage<Duration>) => {
  if (!d) return ''
  return humanizeDuration(Number(d.seconds * BigInt(1000) + BigInt(d.nanos) / BigInt(1000000)), {
    largest: 1, // show only one unit of time
    round: true,
  })
}
