import { Modal, Select, Slider } from 'antd'
import _ from 'lodash'
import { InfoIcon, VolumeXIcon } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'

import {
  useControlSettings,
  useGetConifgurableFactTypes,
  useUpdateControlSettings,
} from '@/api/control'
import { InherentRiskSetting } from '@/gen/inventory/v1/control_service_pb'

import { InherentRiskSettingPriority } from '@/const/priority'

import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { RISK_LEVELS } from '@/lib/proto-types'
import { cn } from '@/lib/style-helpers'

import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { Loading } from '@/components/loading'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { Tooltip } from '@/components/ui/tooltip'
import { useToast } from '@/components/ui/use-toast'

const { confirm } = Modal

const sliderValueToTooltip: Record<number, string> = {
  [InherentRiskSettingPriority[InherentRiskSetting.DISABLED]]: 'No third-parties',
  [InherentRiskSettingPriority[InherentRiskSetting.CRITICAL_AND_ABOVE]]:
    'Critical third-parties only',
  [InherentRiskSettingPriority[InherentRiskSetting.HIGH_AND_ABOVE]]:
    'Critical & High third-parties',
  [InherentRiskSettingPriority[InherentRiskSetting.MEDIUM_AND_ABOVE]]:
    'Critical, High, and Medium third-parties',
  [InherentRiskSettingPriority[InherentRiskSetting.LOW_AND_ABOVE]]: 'All third-parties',
}

const sliderValueToInherentRiskSetting: Record<number, InherentRiskSetting> = {
  [InherentRiskSettingPriority[InherentRiskSetting.DISABLED]]: InherentRiskSetting.DISABLED,
  [InherentRiskSettingPriority[InherentRiskSetting.CRITICAL_AND_ABOVE]]:
    InherentRiskSetting.CRITICAL_AND_ABOVE,
  [InherentRiskSettingPriority[InherentRiskSetting.HIGH_AND_ABOVE]]:
    InherentRiskSetting.HIGH_AND_ABOVE,
  [InherentRiskSettingPriority[InherentRiskSetting.MEDIUM_AND_ABOVE]]:
    InherentRiskSetting.MEDIUM_AND_ABOVE,
  [InherentRiskSettingPriority[InherentRiskSetting.LOW_AND_ABOVE]]:
    InherentRiskSetting.LOW_AND_ABOVE,
}

export const ControlSection = () => {
  const { data: configurableFactTypes } = useGetConifgurableFactTypes()
  const {
    data: { controlSettings, factTypeToControlTypeSettings },
    isLoading,
  } = useControlSettings()
  const { mutateAsync } = useUpdateControlSettings()

  const [newControlSettingByControlId, setNewControlSettingByControlId] = useState<
    Record<string, InherentRiskSetting>
  >({})
  const [newFactTypeSettingsByControlId, setNewFactTypeSettingsByControlId] = useState<
    Record<string, string[]>
  >({})

  const { isEnabled: isFactTypeConfigEnabled } = useFeatureFlagEnabled(
    'certificate-control-configuration',
  )

  const { toast } = useToast()
  const controlSettingByCategory = useMemo(
    () => _.groupBy(controlSettings, 'categoryName'),
    [controlSettings],
  )
  const backendControlSettingByControlId = useMemo(
    () =>
      controlSettings?.reduce(
        (acc, { controlId, inherentRiskSetting }) => {
          acc[controlId] = inherentRiskSetting
          return acc
        },
        {} as Record<string, InherentRiskSetting>,
      ) || {},
    [controlSettings],
  )

  const factTypeOptions = useMemo(
    () => configurableFactTypes?.factTypeToControlTypeOptions,
    [configurableFactTypes],
  )

  const getRelevantFactTypeOptions = useCallback(
    (controlId: string) => {
      return (
        factTypeOptions?.filter(
          ({ excludeFromControlIds }) => !excludeFromControlIds.includes(controlId),
        ) ?? []
      )
    },
    [factTypeOptions],
  )

  const getFactTypeSettings = useCallback(
    (controlId: string) => {
      return (
        factTypeToControlTypeSettings?.filter(({ controlTypeId }) => controlTypeId === controlId) ??
        []
      )
    },
    [factTypeToControlTypeSettings],
  )

  const getDropdownValue = useCallback(
    (controlId: string) => {
      if (controlId in newFactTypeSettingsByControlId) {
        return newFactTypeSettingsByControlId[controlId]
      }
      return getFactTypeSettings(controlId).map(({ factTypeId }) => factTypeId)
    },
    [getFactTypeSettings, newFactTypeSettingsByControlId],
  )

  if (isLoading || !controlSettings) {
    return <Loading />
  }

  const handleChangeSlider = (controlId: string, value: number) => {
    setNewControlSettingByControlId((prev) => {
      const inherentRiskSetting = sliderValueToInherentRiskSetting[value]

      if (inherentRiskSetting === backendControlSettingByControlId[controlId]) {
        const { [controlId]: _, ...rest } = prev
        return rest
      }

      return {
        ...prev,
        [controlId]: inherentRiskSetting,
      }
    })
  }

  const handleFactTypeSettingChange = (controlId: string, selectedFactTypeIds: string[]) => {
    setNewFactTypeSettingsByControlId((prev) => {
      const currentValue = getFactTypeSettings(controlId).map(({ factTypeId }) => factTypeId)
      if (_.isEqual(selectedFactTypeIds.sort(), currentValue.sort())) {
        const { [controlId]: _, ...rest } = prev
        return rest
      }
      return {
        ...prev,
        [controlId]: selectedFactTypeIds,
      }
    })
  }

  const showConfirm = () => {
    confirm({
      centered: true,
      icon: null,
      title: 'Confirm Changes?',
      content: (
        <div>
          <h3 className='mb-1'>Adjusting third-party controls may:</h3>
          <ul className='list-inside list-disc'>
            <li>Uncover new gaps for reactivated controls</li>
            <li>Hide controls with detected gaps</li>
          </ul>
        </div>
      ),
      onOk: async () => {
        const newControlSettings = _.entries(newControlSettingByControlId).map(
          ([controlId, inherentRiskSetting]) => {
            return {
              controlId,
              inherentRiskSetting,
            }
          },
        )
        const allControlIds = controlSettings.map(({ controlId }) => controlId)
        const factTypeState = isFactTypeConfigEnabled
          ? allControlIds.flatMap((controlId) =>
              getDropdownValue(controlId).map((factTypeId) => ({
                controlTypeId: controlId,
                factTypeId,
              })),
            )
          : []

        await mutateAsync(
          {
            controlSettings: newControlSettings,
            factTypeToControlTypeSettings: factTypeState,
          },
          {
            onSuccess: () => {
              toast({
                title: 'Control settings updated successfully',
                status: 'success',
              })
              setNewControlSettingByControlId({})
              setNewFactTypeSettingsByControlId({})
            },
            onError: () => {
              toast({
                title: 'Failed to update control settings',
                status: 'error',
              })
            },
          },
        )
      },
    })
  }

  return (
    <div className='max-w-5xl'>
      <h2 className='mb-3 self-start text-3xl font-bold'>Controls</h2>
      <div className='mb-6 text-sm'>
        Configure which control categories are enforced across your third-parties, and specify
        controls by third-party inherent risk.
      </div>
      <div
        className={cn('grid', {
          'grid-cols-8': !isFactTypeConfigEnabled,
          'grid-cols-11': isFactTypeConfigEnabled,
        })}
      >
        <div className='sticky top-0 z-10 col-span-full grid grid-cols-subgrid grid-rows-2 bg-white pt-3'>
          <div className='col-span-4 col-start-1 row-start-1 flex grow items-center'>
            <span className='text-xs font-bold uppercase text-gray-400'>Control</span>
          </div>
          <div className='col-span-5 col-start-4 row-start-1 flex justify-between self-end px-12'>
            <VolumeXIcon size={20} strokeWidth={1} />
            {RISK_LEVELS.map(({ no }) => {
              return <CompanyRiskIcon showTooltip key={no} riskLevel={no} />
            })}
          </div>
          {isFactTypeConfigEnabled && (
            <div className='col-span-3 col-start-9 row-start-1 flex items-center px-4'>
              <span className='mr-2 text-xs font-semibold uppercase text-gray-500'>
                Auto-validation criteria
              </span>
              <Tooltip trigger={<InfoIcon size={12} />}>
                <span className='text-wrap'>
                  Lema will use the selected certificates to validate controls in cases where no
                  evidence of a gap
                </span>
              </Tooltip>
            </div>
          )}
          <Separator className='col-span-full row-start-2 mt-3 w-full' />
        </div>

        <div className='col-span-full col-start-1 row-auto mt-3 grid grid-cols-subgrid gap-4'>
          {_.entries(controlSettingByCategory).map(([categoryName, controls]) => (
            <div key={categoryName} className='col-span-full col-start-1 grid grid-cols-subgrid'>
              <h3 className='col-span-3 col-start-1 mb-4 font-semibold'>{categoryName}</h3>
              <div className='col-span-full col-start-1 grid w-full grid-cols-subgrid grid-rows-subgrid'>
                <div className='py-2.5'>
                  <Separator orientation='vertical' className='col-start-1 w-0.5 rounded' />
                </div>
                <div className='col-span-full col-start-1 grid grid-cols-subgrid pl-4'>
                  {controls.map(({ controlId, controlTitle }) => {
                    const relevantFactTypeOptions = getRelevantFactTypeOptions(controlId)
                    const dropdownValue = getDropdownValue(controlId)
                    return (
                      <div
                        className='group col-span-full col-start-1 grid grid-cols-subgrid items-center gap-0 space-y-1.5 px-4 py-1 transition-colors duration-100 hover:bg-gray-50'
                        key={controlId}
                      >
                        <div className='col-span-3 col-start-1'>{controlTitle}</div>
                        <div className='col-span-5 col-start-4 px-12'>
                          <Slider
                            onChange={(value) => handleChangeSlider(controlId, value)}
                            value={
                              sliderValueToInherentRiskSetting[
                                _.isNumber(newControlSettingByControlId[controlId])
                                  ? newControlSettingByControlId[controlId]
                                  : backendControlSettingByControlId[controlId]
                              ]
                            }
                            tooltip={{
                              overlayStyle: { maxWidth: '300px' },
                              formatter: (value) =>
                                _.isNumber(value) && sliderValueToTooltip[value],
                            }}
                            dots
                            max={InherentRiskSettingPriority[InherentRiskSetting.LOW_AND_ABOVE]}
                            min={InherentRiskSettingPriority[InherentRiskSetting.DISABLED]}
                          />
                        </div>
                        {isFactTypeConfigEnabled && (
                          <div className='col-span-3 col-start-9 !mt-0 flex items-center justify-center pr-4'>
                            <FactTypeSelect
                              dropdownValue={dropdownValue}
                              relevantFactTypeOptions={relevantFactTypeOptions}
                              handleFactTypeSettingChange={handleFactTypeSettingChange}
                              controlId={controlId}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button
          position='sticky'
          disabled={
            _.isEmpty(newControlSettingByControlId) && _.isEmpty(newFactTypeSettingsByControlId)
          }
          onClick={showConfirm}
          className='bottom-5 col-span-1 col-start-11 mt-4 w-fit self-end justify-self-end'
        >
          Save
        </Button>
      </div>
    </div>
  )
}

type FactTypeSelectProps = {
  dropdownValue: string[]
  relevantFactTypeOptions: { factTypeId: string; title: string }[]
  handleFactTypeSettingChange: (controlId: string, selectedFactTypeIds: string[]) => void
  controlId: string
}
const FactTypeSelect = ({
  dropdownValue,
  relevantFactTypeOptions,
  handleFactTypeSettingChange,
  controlId,
}: FactTypeSelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <Select
      popupMatchSelectWidth={false}
      className={cn(
        'w-5/6 opacity-0 transition-opacity duration-100 group-hover:opacity-100 [&>.ant-select-selector]:!cursor-pointer',
        {
          'opacity-100': dropdownValue.length > 0 || isOpen,
        },
      )}
      variant={dropdownValue.length > 0 ? 'borderless' : 'outlined'}
      onDropdownVisibleChange={setIsOpen}
      maxTagCount={1}
      tagRender={(props) => {
        return <span className='text-sm text-gray-500'>{props.label}</span>
      }}
      maxTagPlaceholder={
        <span className='ml-2 font-bold text-gray-500'>+ {dropdownValue.length - 1}</span>
      }
      placeholder='Add certificates'
      mode='multiple'
      onChange={(value) => handleFactTypeSettingChange(controlId, value)}
      value={dropdownValue}
      options={relevantFactTypeOptions.map(({ factTypeId, title }) => ({
        label: title,
        value: factTypeId,
      }))}
    />
  )
}
