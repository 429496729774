import { ThemeConfig } from 'antd'
import { VictoryThemeDefinition } from 'victory'

import { colors } from './colors'

const { gray, purple } = colors

export const victoryPolarChartTheme: VictoryThemeDefinition = {
  polarAxis: {
    style: {
      axisLabel: { fill: gray[500], fontFamily: "'Manrope', sans-serif", fontSize: 13 },
      grid: { stroke: gray[100] },
      axis: { stroke: gray[200], strokeDasharray: '2' },
    },
  },
}

export const antdTheme: ThemeConfig = {
  components: {
    Input: {
      fontSizeLG: 13,
      fontSize: 13,
    },
    Select: {
      fontSizeLG: 13,
      colorPrimary: gray[700],
      optionActiveBg: gray[50],
      optionSelectedBg: gray[100],
    },
    Slider: {
      handleActiveColor: gray[700],
      handleColor: gray[700],
      handleSize: 8,
      handleSizeHover: 10,
      trackBg: gray[700],
      dotActiveBorderColor: gray[700],
    },
    Carousel: {
      dotWidth: 22,
      dotActiveWidth: 44,
      dotHeight: 4,
    },
    Steps: {
      controlItemBgActive: purple[100],
      colorPrimary: purple[600],
    },
  },
  token: {
    colorPrimary: gray[700],
    colorText: gray[700],
    colorBorder: gray[200],
    fontFamily: 'Manrope',
    borderRadius: 4,
    colorTextPlaceholder: gray[400],
    colorTextDescription: gray[400],
    colorTextDisabled: gray[400],
  },
}
