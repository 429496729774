import { PlusIcon } from 'lucide-react'
import { Link } from 'react-router-dom'

import { useIntegrations } from '@/api/get-integrations.hook'
import {
  Integration,
  IntegrationCategory,
  IntegrationStatus,
} from '@/gen/inventory/v1/integration_service_pb'

import { categoryLabel } from '@/const/label'

import { formatDate } from '@/lib/date'

import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'

export const IntegrationSection = () => {
  return (
    <div>
      <div className='mb-5 flex justify-between'>
        <h2 className='text-3xl font-bold'>Integrations</h2>
        <Link to='/integrations/marketplace'>
          <Button className='flex items-center gap-2 px-4 py-2'>
            <PlusIcon size={14} />
            <span>Add Integration</span>
          </Button>
        </Link>
      </div>
      <IntegrationTable />
    </div>
  )
}

const columns: ColumnType<Integration>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    search: true,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    render: (category: IntegrationCategory) => (
      <Badge variant={'outline'} className='rounded'>
        {categoryLabel[category]}
      </Badge>
    ),
    accessor: ({ category }) => ({
      filterValue: categoryLabel[category || IntegrationCategory.UNSPECIFIED],
    }),
    filter: true,
  },
  {
    title: 'Date Added',
    dataIndex: 'dateAdded',
    render: (date: Integration['dateAdded']) =>
      date && <div className='text-md font-light'>{formatDate(date.toDate())}</div>,
  },
]

export const IntegrationTable = () => {
  const { data, isLoading } = useIntegrations()

  return (
    <Table
      loading={isLoading}
      stickyFilterBar
      columns={columns}
      dataSource={data.filter(({ status }) => status === IntegrationStatus.ADDED)}
    />
  )
}
