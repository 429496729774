// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file questionnaire/v1/template_service.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { QuestionnaireLink } from "./model_pb.js";

/**
 * @generated from enum questionnaire.v1.QuestionType
 */
export enum QuestionType {
  /**
   * @generated from enum value: QUESTION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: QUESTION_TYPE_RADIO = 1;
   */
  RADIO = 1,

  /**
   * @generated from enum value: QUESTION_TYPE_CHECKBOX = 2;
   */
  CHECKBOX = 2,

  /**
   * @generated from enum value: QUESTION_TYPE_FREE_TEXT = 3;
   */
  FREE_TEXT = 3,

  /**
   * @generated from enum value: QUESTION_TYPE_FILE = 4;
   */
  FILE = 4,

  /**
   * @generated from enum value: QUESTION_TYPE_DATE = 5;
   */
  DATE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(QuestionType)
proto3.util.setEnumType(QuestionType, "questionnaire.v1.QuestionType", [
  { no: 0, name: "QUESTION_TYPE_UNSPECIFIED" },
  { no: 1, name: "QUESTION_TYPE_RADIO" },
  { no: 2, name: "QUESTION_TYPE_CHECKBOX" },
  { no: 3, name: "QUESTION_TYPE_FREE_TEXT" },
  { no: 4, name: "QUESTION_TYPE_FILE" },
  { no: 5, name: "QUESTION_TYPE_DATE" },
]);

/**
 * @generated from message questionnaire.v1.ImportRequest
 */
export class ImportRequest extends Message<ImportRequest> {
  /**
   * file contents as bytes
   *
   * @generated from field: bytes template = 1;
   */
  template = new Uint8Array(0);

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: optional string description = 3;
   */
  description?: string;

  constructor(data?: PartialMessage<ImportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ImportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportRequest {
    return new ImportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportRequest {
    return new ImportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportRequest {
    return new ImportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImportRequest | PlainMessage<ImportRequest> | undefined, b: ImportRequest | PlainMessage<ImportRequest> | undefined): boolean {
    return proto3.util.equals(ImportRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ImportResponse
 */
export class ImportResponse extends Message<ImportResponse> {
  /**
   * @generated from field: string template_id = 1;
   */
  templateId = "";

  /**
   * @generated from field: int32 question_count = 2;
   */
  questionCount = 0;

  constructor(data?: PartialMessage<ImportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ImportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportResponse {
    return new ImportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportResponse {
    return new ImportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportResponse {
    return new ImportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ImportResponse | PlainMessage<ImportResponse> | undefined, b: ImportResponse | PlainMessage<ImportResponse> | undefined): boolean {
    return proto3.util.equals(ImportResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ListTemplatesRequest
 */
export class ListTemplatesRequest extends Message<ListTemplatesRequest> {
  constructor(data?: PartialMessage<ListTemplatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ListTemplatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTemplatesRequest {
    return new ListTemplatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTemplatesRequest {
    return new ListTemplatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTemplatesRequest {
    return new ListTemplatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTemplatesRequest | PlainMessage<ListTemplatesRequest> | undefined, b: ListTemplatesRequest | PlainMessage<ListTemplatesRequest> | undefined): boolean {
    return proto3.util.equals(ListTemplatesRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ListTemplatesResponse
 */
export class ListTemplatesResponse extends Message<ListTemplatesResponse> {
  /**
   * @generated from field: repeated questionnaire.v1.QuestionnaireTemplate templates = 1;
   */
  templates: QuestionnaireTemplate[] = [];

  constructor(data?: PartialMessage<ListTemplatesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ListTemplatesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "templates", kind: "message", T: QuestionnaireTemplate, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTemplatesResponse {
    return new ListTemplatesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTemplatesResponse {
    return new ListTemplatesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTemplatesResponse {
    return new ListTemplatesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTemplatesResponse | PlainMessage<ListTemplatesResponse> | undefined, b: ListTemplatesResponse | PlainMessage<ListTemplatesResponse> | undefined): boolean {
    return proto3.util.equals(ListTemplatesResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.QuestionnaireTemplate
 */
export class QuestionnaireTemplate extends Message<QuestionnaireTemplate> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: optional string description = 3;
   */
  description?: string;

  /**
   * @generated from field: int32 question_count = 4;
   */
  questionCount = 0;

  /**
   * @generated from field: repeated questionnaire.v1.QuestionnaireTemplateSection sections = 5;
   */
  sections: QuestionnaireTemplateSection[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: optional questionnaire.v1.ExemptionDocConfig exemption_doc_config = 7;
   */
  exemptionDocConfig?: ExemptionDocConfig;

  /**
   * @generated from field: bool is_default = 8;
   */
  isDefault = false;

  constructor(data?: PartialMessage<QuestionnaireTemplate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.QuestionnaireTemplate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "question_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "sections", kind: "message", T: QuestionnaireTemplateSection, repeated: true },
    { no: 6, name: "updated_at", kind: "message", T: Timestamp },
    { no: 7, name: "exemption_doc_config", kind: "message", T: ExemptionDocConfig, opt: true },
    { no: 8, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionnaireTemplate {
    return new QuestionnaireTemplate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionnaireTemplate {
    return new QuestionnaireTemplate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionnaireTemplate {
    return new QuestionnaireTemplate().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionnaireTemplate | PlainMessage<QuestionnaireTemplate> | undefined, b: QuestionnaireTemplate | PlainMessage<QuestionnaireTemplate> | undefined): boolean {
    return proto3.util.equals(QuestionnaireTemplate, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.QuestionnaireTemplateSection
 */
export class QuestionnaireTemplateSection extends Message<QuestionnaireTemplateSection> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int32 question_count = 2;
   */
  questionCount = 0;

  constructor(data?: PartialMessage<QuestionnaireTemplateSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.QuestionnaireTemplateSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionnaireTemplateSection {
    return new QuestionnaireTemplateSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionnaireTemplateSection {
    return new QuestionnaireTemplateSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionnaireTemplateSection {
    return new QuestionnaireTemplateSection().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionnaireTemplateSection | PlainMessage<QuestionnaireTemplateSection> | undefined, b: QuestionnaireTemplateSection | PlainMessage<QuestionnaireTemplateSection> | undefined): boolean {
    return proto3.util.equals(QuestionnaireTemplateSection, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetTemplateRequest
 */
export class GetTemplateRequest extends Message<GetTemplateRequest> {
  /**
   * @generated from field: string template_id = 1;
   */
  templateId = "";

  constructor(data?: PartialMessage<GetTemplateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetTemplateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTemplateRequest {
    return new GetTemplateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTemplateRequest {
    return new GetTemplateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTemplateRequest {
    return new GetTemplateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTemplateRequest | PlainMessage<GetTemplateRequest> | undefined, b: GetTemplateRequest | PlainMessage<GetTemplateRequest> | undefined): boolean {
    return proto3.util.equals(GetTemplateRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetTemplateResponse
 */
export class GetTemplateResponse extends Message<GetTemplateResponse> {
  /**
   * @generated from field: questionnaire.v1.QuestionnaireTemplate template = 1;
   */
  template?: QuestionnaireTemplate;

  constructor(data?: PartialMessage<GetTemplateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetTemplateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template", kind: "message", T: QuestionnaireTemplate },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTemplateResponse {
    return new GetTemplateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTemplateResponse {
    return new GetTemplateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTemplateResponse {
    return new GetTemplateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTemplateResponse | PlainMessage<GetTemplateResponse> | undefined, b: GetTemplateResponse | PlainMessage<GetTemplateResponse> | undefined): boolean {
    return proto3.util.equals(GetTemplateResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.CreateQuestionnaireRequest
 */
export class CreateQuestionnaireRequest extends Message<CreateQuestionnaireRequest> {
  /**
   * @generated from field: string template_id = 1;
   */
  templateId = "";

  /**
   * @generated from field: repeated string recipients = 2;
   */
  recipients: string[] = [];

  /**
   * @generated from field: string company_id = 3;
   */
  companyId = "";

  /**
   * @generated from field: google.protobuf.Timestamp due_date = 4;
   */
  dueDate?: Timestamp;

  /**
   * if empty, all sections are selected
   *
   * @generated from field: repeated string selected_sections = 5;
   */
  selectedSections: string[] = [];

  /**
   * @generated from field: questionnaire.v1.ExemptionDocConfig exemption_doc_config = 6;
   */
  exemptionDocConfig?: ExemptionDocConfig;

  /**
   * @generated from field: optional string contact_email = 7;
   */
  contactEmail?: string;

  constructor(data?: PartialMessage<CreateQuestionnaireRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.CreateQuestionnaireRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recipients", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "due_date", kind: "message", T: Timestamp },
    { no: 5, name: "selected_sections", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "exemption_doc_config", kind: "message", T: ExemptionDocConfig },
    { no: 7, name: "contact_email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateQuestionnaireRequest {
    return new CreateQuestionnaireRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateQuestionnaireRequest {
    return new CreateQuestionnaireRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateQuestionnaireRequest {
    return new CreateQuestionnaireRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateQuestionnaireRequest | PlainMessage<CreateQuestionnaireRequest> | undefined, b: CreateQuestionnaireRequest | PlainMessage<CreateQuestionnaireRequest> | undefined): boolean {
    return proto3.util.equals(CreateQuestionnaireRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.CreateQuestionnaireResponse
 */
export class CreateQuestionnaireResponse extends Message<CreateQuestionnaireResponse> {
  /**
   * @generated from field: string questionnaire_id = 1;
   */
  questionnaireId = "";

  /**
   * @generated from field: repeated questionnaire.v1.QuestionnaireLink links = 2;
   */
  links: QuestionnaireLink[] = [];

  constructor(data?: PartialMessage<CreateQuestionnaireResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.CreateQuestionnaireResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionnaire_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "links", kind: "message", T: QuestionnaireLink, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateQuestionnaireResponse {
    return new CreateQuestionnaireResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateQuestionnaireResponse {
    return new CreateQuestionnaireResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateQuestionnaireResponse {
    return new CreateQuestionnaireResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateQuestionnaireResponse | PlainMessage<CreateQuestionnaireResponse> | undefined, b: CreateQuestionnaireResponse | PlainMessage<CreateQuestionnaireResponse> | undefined): boolean {
    return proto3.util.equals(CreateQuestionnaireResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ListTemplateQuestionRequest
 */
export class ListTemplateQuestionRequest extends Message<ListTemplateQuestionRequest> {
  /**
   * @generated from field: string template_id = 1;
   */
  templateId = "";

  constructor(data?: PartialMessage<ListTemplateQuestionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ListTemplateQuestionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTemplateQuestionRequest {
    return new ListTemplateQuestionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTemplateQuestionRequest {
    return new ListTemplateQuestionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTemplateQuestionRequest {
    return new ListTemplateQuestionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTemplateQuestionRequest | PlainMessage<ListTemplateQuestionRequest> | undefined, b: ListTemplateQuestionRequest | PlainMessage<ListTemplateQuestionRequest> | undefined): boolean {
    return proto3.util.equals(ListTemplateQuestionRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ListTemplateQuestionResponse
 */
export class ListTemplateQuestionResponse extends Message<ListTemplateQuestionResponse> {
  /**
   * @generated from field: repeated questionnaire.v1.TemplateQuestion questions = 1;
   */
  questions: TemplateQuestion[] = [];

  constructor(data?: PartialMessage<ListTemplateQuestionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ListTemplateQuestionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questions", kind: "message", T: TemplateQuestion, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTemplateQuestionResponse {
    return new ListTemplateQuestionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTemplateQuestionResponse {
    return new ListTemplateQuestionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTemplateQuestionResponse {
    return new ListTemplateQuestionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTemplateQuestionResponse | PlainMessage<ListTemplateQuestionResponse> | undefined, b: ListTemplateQuestionResponse | PlainMessage<ListTemplateQuestionResponse> | undefined): boolean {
    return proto3.util.equals(ListTemplateQuestionResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.TemplateQuestion
 */
export class TemplateQuestion extends Message<TemplateQuestion> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string section = 2;
   */
  section = "";

  /**
   * @generated from field: string sub_section = 3;
   */
  subSection = "";

  /**
   * @generated from field: string question = 4;
   */
  question = "";

  /**
   * @generated from field: string note = 5;
   */
  note = "";

  /**
   * @generated from field: bool leading_question = 6;
   */
  leadingQuestion = false;

  /**
   * @generated from field: questionnaire.v1.QuestionType type = 7;
   */
  type = QuestionType.UNSPECIFIED;

  /**
   * @generated from field: repeated questionnaire.v1.TemplateOption options = 8;
   */
  options: TemplateOption[] = [];

  constructor(data?: PartialMessage<TemplateQuestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.TemplateQuestion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sub_section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "leading_question", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "type", kind: "enum", T: proto3.getEnumType(QuestionType) },
    { no: 8, name: "options", kind: "message", T: TemplateOption, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateQuestion {
    return new TemplateQuestion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateQuestion {
    return new TemplateQuestion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateQuestion {
    return new TemplateQuestion().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateQuestion | PlainMessage<TemplateQuestion> | undefined, b: TemplateQuestion | PlainMessage<TemplateQuestion> | undefined): boolean {
    return proto3.util.equals(TemplateQuestion, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.TemplateOption
 */
export class TemplateOption extends Message<TemplateOption> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: bool is_flag = 2;
   */
  isFlag = false;

  /**
   * @generated from field: string label = 3;
   */
  label = "";

  /**
   * @generated from field: bool needs_explanation = 4;
   */
  needsExplanation = false;

  constructor(data?: PartialMessage<TemplateOption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.TemplateOption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_flag", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "needs_explanation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateOption {
    return new TemplateOption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateOption {
    return new TemplateOption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateOption {
    return new TemplateOption().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateOption | PlainMessage<TemplateOption> | undefined, b: TemplateOption | PlainMessage<TemplateOption> | undefined): boolean {
    return proto3.util.equals(TemplateOption, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.UpdateTemplateRequest
 */
export class UpdateTemplateRequest extends Message<UpdateTemplateRequest> {
  /**
   * @generated from field: string template_id = 1;
   */
  templateId = "";

  /**
   * @generated from field: optional string name = 2;
   */
  name?: string;

  /**
   * @generated from field: optional string description = 3;
   */
  description?: string;

  /**
   * @generated from field: optional questionnaire.v1.ExemptionDocConfig exemption_doc_config = 4;
   */
  exemptionDocConfig?: ExemptionDocConfig;

  /**
   * @generated from field: optional bool is_default = 5;
   */
  isDefault?: boolean;

  constructor(data?: PartialMessage<UpdateTemplateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.UpdateTemplateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "exemption_doc_config", kind: "message", T: ExemptionDocConfig, opt: true },
    { no: 5, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTemplateRequest {
    return new UpdateTemplateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTemplateRequest {
    return new UpdateTemplateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTemplateRequest {
    return new UpdateTemplateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTemplateRequest | PlainMessage<UpdateTemplateRequest> | undefined, b: UpdateTemplateRequest | PlainMessage<UpdateTemplateRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTemplateRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ExemptionDocConfig
 */
export class ExemptionDocConfig extends Message<ExemptionDocConfig> {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled = false;

  /**
   * @generated from field: optional string sub_category_id = 2;
   */
  subCategoryId?: string;

  /**
   * @generated from field: optional string sub_category_name = 3;
   */
  subCategoryName?: string;

  /**
   * @generated from field: optional string category_id = 4;
   */
  categoryId?: string;

  constructor(data?: PartialMessage<ExemptionDocConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ExemptionDocConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "sub_category_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "sub_category_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "category_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExemptionDocConfig {
    return new ExemptionDocConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExemptionDocConfig {
    return new ExemptionDocConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExemptionDocConfig {
    return new ExemptionDocConfig().fromJsonString(jsonString, options);
  }

  static equals(a: ExemptionDocConfig | PlainMessage<ExemptionDocConfig> | undefined, b: ExemptionDocConfig | PlainMessage<ExemptionDocConfig> | undefined): boolean {
    return proto3.util.equals(ExemptionDocConfig, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.UpdateTemplateResponse
 */
export class UpdateTemplateResponse extends Message<UpdateTemplateResponse> {
  constructor(data?: PartialMessage<UpdateTemplateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.UpdateTemplateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTemplateResponse {
    return new UpdateTemplateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTemplateResponse {
    return new UpdateTemplateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTemplateResponse {
    return new UpdateTemplateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTemplateResponse | PlainMessage<UpdateTemplateResponse> | undefined, b: UpdateTemplateResponse | PlainMessage<UpdateTemplateResponse> | undefined): boolean {
    return proto3.util.equals(UpdateTemplateResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.RenameSectionRequest
 */
export class RenameSectionRequest extends Message<RenameSectionRequest> {
  /**
   * @generated from field: string template_id = 1;
   */
  templateId = "";

  /**
   * @generated from field: string current_name = 2;
   */
  currentName = "";

  /**
   * @generated from field: string new_name = 3;
   */
  newName = "";

  constructor(data?: PartialMessage<RenameSectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.RenameSectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "current_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "new_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RenameSectionRequest {
    return new RenameSectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RenameSectionRequest {
    return new RenameSectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RenameSectionRequest {
    return new RenameSectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RenameSectionRequest | PlainMessage<RenameSectionRequest> | undefined, b: RenameSectionRequest | PlainMessage<RenameSectionRequest> | undefined): boolean {
    return proto3.util.equals(RenameSectionRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.RenameSectionResponse
 */
export class RenameSectionResponse extends Message<RenameSectionResponse> {
  constructor(data?: PartialMessage<RenameSectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.RenameSectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RenameSectionResponse {
    return new RenameSectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RenameSectionResponse {
    return new RenameSectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RenameSectionResponse {
    return new RenameSectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RenameSectionResponse | PlainMessage<RenameSectionResponse> | undefined, b: RenameSectionResponse | PlainMessage<RenameSectionResponse> | undefined): boolean {
    return proto3.util.equals(RenameSectionResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.UpdateQuestionRequest
 */
export class UpdateQuestionRequest extends Message<UpdateQuestionRequest> {
  /**
   * @generated from field: string template_id = 1;
   */
  templateId = "";

  /**
   * @generated from field: int32 question_id = 2;
   */
  questionId = 0;

  /**
   * @generated from field: optional bool leading_question = 3;
   */
  leadingQuestion?: boolean;

  /**
   * @generated from field: optional questionnaire.v1.QuestionType type = 4;
   */
  type?: QuestionType;

  /**
   * @generated from field: optional string question = 5;
   */
  question?: string;

  /**
   * @generated from field: optional string note = 6;
   */
  note?: string;

  constructor(data?: PartialMessage<UpdateQuestionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.UpdateQuestionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "leading_question", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(QuestionType), opt: true },
    { no: 5, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateQuestionRequest {
    return new UpdateQuestionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateQuestionRequest {
    return new UpdateQuestionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateQuestionRequest {
    return new UpdateQuestionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateQuestionRequest | PlainMessage<UpdateQuestionRequest> | undefined, b: UpdateQuestionRequest | PlainMessage<UpdateQuestionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateQuestionRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.UpdateQuestionResponse
 */
export class UpdateQuestionResponse extends Message<UpdateQuestionResponse> {
  constructor(data?: PartialMessage<UpdateQuestionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.UpdateQuestionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateQuestionResponse {
    return new UpdateQuestionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateQuestionResponse {
    return new UpdateQuestionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateQuestionResponse {
    return new UpdateQuestionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateQuestionResponse | PlainMessage<UpdateQuestionResponse> | undefined, b: UpdateQuestionResponse | PlainMessage<UpdateQuestionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateQuestionResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.UpdateOptionRequest
 */
export class UpdateOptionRequest extends Message<UpdateOptionRequest> {
  /**
   * @generated from field: string template_id = 1;
   */
  templateId = "";

  /**
   * @generated from field: int32 question_id = 2;
   */
  questionId = 0;

  /**
   * @generated from field: string option_id = 3;
   */
  optionId = "";

  /**
   * @generated from field: optional bool is_flag = 4;
   */
  isFlag?: boolean;

  /**
   * @generated from field: optional string label = 5;
   */
  label?: string;

  /**
   * @generated from field: optional bool needs_explanation = 6;
   */
  needsExplanation?: boolean;

  constructor(data?: PartialMessage<UpdateOptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.UpdateOptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "option_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_flag", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "needs_explanation", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOptionRequest {
    return new UpdateOptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOptionRequest {
    return new UpdateOptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOptionRequest {
    return new UpdateOptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOptionRequest | PlainMessage<UpdateOptionRequest> | undefined, b: UpdateOptionRequest | PlainMessage<UpdateOptionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateOptionRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.UpdateOptionResponse
 */
export class UpdateOptionResponse extends Message<UpdateOptionResponse> {
  constructor(data?: PartialMessage<UpdateOptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.UpdateOptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOptionResponse {
    return new UpdateOptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOptionResponse {
    return new UpdateOptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOptionResponse {
    return new UpdateOptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOptionResponse | PlainMessage<UpdateOptionResponse> | undefined, b: UpdateOptionResponse | PlainMessage<UpdateOptionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateOptionResponse, a, b);
  }
}

