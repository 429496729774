import { cva } from 'class-variance-authority'

import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelLabel } from '@/const/label'

import { riskLevelClassNameColor } from '@/lib/color'
import { cn } from '@/lib/style-helpers'

import { Tooltip } from '@/components/ui/tooltip'

import Critical from '@/assets/tier-critical.svg'
import High from '@/assets/tier-high.svg'
import Low from '@/assets/tier-low.svg'
import Medium from '@/assets/tier-medium.svg'
import Unspecified from '@/assets/tier-unspecified.svg'

const BARS_MASK_URL = '/icons/4bars.svg'

const sweepVariants = cva(
  'size-full animate-sweep bg-gradient-to-r from-transparent to-transparent will-change-transform',
  {
    variants: {
      color: {
        purple: 'via-purple-400',
        green: 'via-green-400',
        yellow: 'via-yellow-400',
        red: 'via-red-400',
        gray: 'via-gray-600/50',
      },
      speed: {
        superSlow: 'duration-4000',
        slow: 'duration-3000',
        medium: 'duration-2000',
        fast: 'duration-1000',
        superFast: 'duration-500',
      },
      defaultVariants: {
        speed: 'slow',
        color: 'gray',
      },
    },
  },
)

export type CompanyRiskIconProps = {
  riskLevel: RiskLevel
  showTooltip?: boolean
  calculatedRisk?: RiskLevel
}

export const CompanyRiskIcon = ({
  riskLevel,
  showTooltip = false,
  calculatedRisk,
}: CompanyRiskIconProps) => {
  // We compare risklevel with LOW because the backend returns LOW when the risk level is UNSPECIFIED
  // This may only cause a short discrepancy when the risk level is just being calculated and the user set the risk level to LOW
  const isProjectionDone = calculatedRisk !== RiskLevel.UNSPECIFIED || riskLevel !== RiskLevel.LOW
  const Icon = riskLevelToIcon[isProjectionDone ? riskLevel : RiskLevel.UNSPECIFIED]

  if (!Icon) return null

  return (
    <Tooltip
      tooltipDisabled={!showTooltip}
      trigger={
        <div className='relative'>
          <Icon
            className={cn(
              'isolate h-5 min-h-5 w-5 min-w-5',
              riskLevelClassNameColor[isProjectionDone ? riskLevel : RiskLevel.UNSPECIFIED],
            )}
          />
          {!isProjectionDone && (
            <div
              className='absolute inset-0 isolate overflow-hidden'
              style={{
                maskImage: `url(${BARS_MASK_URL})`,
                maskRepeat: 'no-repeat',
                maskPosition: 'center',
              }}
            >
              <div className={sweepVariants({ color: 'gray', speed: 'slow' })} />
            </div>
          )}
        </div>
      }
    >
      {isProjectionDone ? (
        <div className='capitalize'>Inherent Risk: {riskLevelLabel[riskLevel]}</div>
      ) : (
        <span>Estimating inherent risk</span>
      )}
    </Tooltip>
  )
}

const riskLevelToIcon: Record<RiskLevel, typeof Critical | null> = {
  [RiskLevel.CRITICAL]: Critical,
  [RiskLevel.HIGH]: High,
  [RiskLevel.MEDIUM]: Medium,
  [RiskLevel.LOW]: Low,
  [RiskLevel.UNSPECIFIED]: Unspecified,
}
