import { proto3 } from '@bufbuild/protobuf'

import { SOC2Scope } from '@/gen/inventory/v1/artifact_service_pb'
import { ControlStatus } from '@/gen/inventory/v1/control_service_pb'
import { Implication } from '@/gen/inventory/v1/implication_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { sortControlStatus, sortRiskLevel } from '@/const/priority'

// All control statuses sorted by priority
export const CONTROL_STATUS = proto3
  .getEnumType(ControlStatus)
  .values.filter(({ no }) => no !== ControlStatus.UNSPECIFIED)
  .sort(({ no: a }, { no: b }) => sortControlStatus(a, b)) as Array<{
  no: ControlStatus
  name: string
}>

// All risk levels sorted by priority
export const RISK_LEVELS = proto3
  .getEnumType(RiskLevel)
  .values.filter(({ no }) => no !== RiskLevel.UNSPECIFIED)
  .sort(({ no: a }, { no: b }) => sortRiskLevel(a, b)) as Array<{ no: RiskLevel; name: string }>

export const IMPLICATIONS = proto3
  .getEnumType(Implication)
  .values.filter(({ no }) => no !== Implication.UNSPECIFIED)

export const SOC2_SCOPES = proto3
  .getEnumType(SOC2Scope)
  .values.filter(({ no }) => no !== SOC2Scope.SOC2_SCOPE_UNSPECIFIED)
