import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { getQuestionnaire } from '@/gen/questionnaire/v1/assessor_service-AssessorService_connectquery'
import {
  getTemplate,
  listTemplateQuestion,
  listTemplates,
  renameSection,
  updateOption,
  updateQuestion,
  updateTemplate,
} from '@/gen/questionnaire/v1/template_service-TemplateService_connectquery'

export const useGetQuestionnaireTemplate = ({ templateId }: { templateId: string }) => {
  const queryState = useQuery(getTemplate.useQuery({ templateId: templateId }))
  return {
    ...queryState,
    data: queryState.data?.template,
  }
}

export const useListQuestionnaireTemplates = () => {
  const queryState = useQuery(listTemplates.useQuery())
  return {
    ...queryState,
    data: queryState.data?.templates || [],
  }
}

export const useListTemplateQuestion = (templateId?: string) => {
  const queryState = useQuery(listTemplateQuestion.useQuery({ templateId }))

  return {
    ...queryState,
    data: queryState.data?.questions || [],
  }
}

export const useRenameSection = () => {
  const queryClient = useQueryClient()
  const { queryKey: getQuestionnaireKey } = getQuestionnaire.useQuery()
  const { queryKey: listTemplateQuestionKey } = listTemplateQuestion.useQuery()
  const { queryKey: getTemplateKey } = getTemplate.useQuery()

  return useMutation(renameSection.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQuestionnaireKey)
      queryClient.invalidateQueries(listTemplateQuestionKey)
      queryClient.invalidateQueries(getTemplateKey)
    },
  })
}

export const useUpdateTemplateQuestion = () => {
  const queryClient = useQueryClient()
  const { queryKey: getQuestionnaireKey } = getQuestionnaire.useQuery()
  const { queryKey: listTemplateQuestionKey } = listTemplateQuestion.useQuery()
  const { queryKey: getTemplateKey } = getTemplate.useQuery()
  return useMutation(updateQuestion.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQuestionnaireKey)
      queryClient.invalidateQueries(listTemplateQuestionKey)
      queryClient.invalidateQueries(getTemplateKey)
    },
  })
}

export const useUpdateTemplateOption = () => {
  const queryClient = useQueryClient()
  const { queryKey: getQuestionnaireKey } = getQuestionnaire.useQuery()
  const { queryKey: listTemplateQuestionKey } = listTemplateQuestion.useQuery()
  const { queryKey: getTemplateKey } = getTemplate.useQuery()
  return useMutation(updateOption.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQuestionnaireKey)
      queryClient.invalidateQueries(listTemplateQuestionKey)
      queryClient.invalidateQueries(getTemplateKey)
    },
  })
}

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient()
  const { queryKey: getTemplateKey } = getTemplate.useQuery()
  const { queryKey: listTemplateQuestionKey } = listTemplates.useQuery()
  return useMutation(updateTemplate.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getTemplateKey)
      queryClient.invalidateQueries(listTemplateQuestionKey)
    },
  })
}
