import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { cn } from '@/lib/style-helpers'

export interface Tab {
  link: string
  title: string
  component: React.ReactNode
  isDisabled?: boolean
  isVisible?: boolean
}

export interface LinkTabsProps {
  tabs: Tab[]
}

export const LinkTabs: React.FC<LinkTabsProps> = ({ tabs }) => {
  const location = useLocation()
  const visibleTabs = useMemo(() => tabs.filter((tab) => tab.isVisible !== false), [tabs])
  return (
    <>
      <div className='flex gap-2'>
        {visibleTabs.map((tab) => {
          const isCurrentPage = location.pathname === tab.link
          const isActive = location.pathname.startsWith(tab.link)
          return (
            <Link
              key={tab.link}
              onClick={() => isCurrentPage && window.location.reload()}
              aria-disabled={tab.isDisabled}
              to={tab.isDisabled ? '#' : tab.link}
              className={cn('mx-3 px-1 py-2', {
                'cursor-not-allowed': tab.isDisabled,
                'opacity-40': tab.isDisabled,
                'font-semibold': isActive,
                'border-b-2 border-gray-700 transition-colors duration-300 ease-in-out':
                  isActive && !tab.isDisabled,
              })}
            >
              {tab.title}
            </Link>
          )
        })}
      </div>
    </>
  )
}
