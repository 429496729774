import { useMutation, useQuery } from '@tanstack/react-query'

import {
  listSlackChannels,
  triggerNotification,
} from '@/gen/notifications/v1/notifications_service-NotificationsService_connectquery'
import { Actor, NotificationType } from '@/gen/notifications/v1/notifications_service_pb'

export const useListSlackChannels = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const queryState = useQuery(listSlackChannels.useQuery({ enabled }))
  return {
    ...queryState,
    data: queryState.data?.channels || [],
  }
}

type BaseNotificationProps = {
  roomId: string
  userId: string
  actor: Actor
  type?: NotificationType
  threadId?: string
  magicLink?: string
}
export const useTriggerNotification = () => {
  return useMutation(triggerNotification.useMutation().mutationFn, {})
}

export const useNotifyUserEnteredQuestionnaire = () => {
  const { mutateAsync } = useTriggerNotification()
  return useMutation(({ roomId, userId, actor, magicLink }: BaseNotificationProps) =>
    mutateAsync({
      type: NotificationType.USER_ENTERED_QUESTIONNAIRE,
      roomId,
      userId,
      actor,
      magicLink,
    }),
  )
}

export const useNotifyQuestionUpdated = () => {
  const { mutateAsync } = useTriggerNotification()
  return useMutation(({ roomId, userId, threadId, actor, magicLink }: BaseNotificationProps) =>
    mutateAsync({
      type: NotificationType.QUESTION_UPDATED,
      roomId,
      userId,
      actor,
      threadId,
      magicLink,
    }),
  )
}

export const useNotifyThreadUnresolved = () => {
  const { mutateAsync } = useTriggerNotification()
  return useMutation(({ roomId, userId, threadId, actor, magicLink }: BaseNotificationProps) =>
    mutateAsync({
      type: NotificationType.THREAD_MARKED_UNRESOLVED,
      roomId,
      userId,
      actor,
      threadId,
      magicLink,
    }),
  )
}

type NotifyCommentAddedProps = BaseNotificationProps & {
  comment: string
  questionId?: string
}
export const useNotifyCommentAdded = () => {
  const { mutateAsync } = useTriggerNotification()
  return useMutation(
    ({
      roomId,
      userId,
      threadId,
      actor,
      comment,
      magicLink,
      questionId,
    }: NotifyCommentAddedProps) =>
      mutateAsync({
        type: NotificationType.COMMENT_ADDED,
        roomId,
        userId,
        actor,
        threadId,
        comment,
        magicLink,
        questionId,
      }),
  )
}
