import { Modal } from 'antd'
import _ from 'lodash'
import { MoreVerticalIcon, RefreshCcwIcon, SendIcon, Trash2Icon } from 'lucide-react'

import { useDeleteOrgArtifact, useListUserSourcedArtifact } from '@/api/artifact.hook'
import { useGetCompany } from '@/api/company.hook'
import { usePublishArtifact, useRetryArtifact } from '@/api/document.hook'
import { Artifact, DocumentSource, DocumentStatus } from '@/gen/inventory/v1/artifact_service_pb'

import { documentSourceLabel, documentStatusLabel } from '@/const/label'
import { documentStatusPriority } from '@/const/priority'

import { formatDate } from '@/lib/date'
import { useIsSupportUser } from '@/lib/support-user'
import { useUrlParams } from '@/lib/url-param.hook'

import { ArtifactHyperlink } from '@/components/artifact-hyperlink'
import { ArtifactExpirationDate } from '@/components/badge/artifact-expiration-date'
import { DocumentStatusBadge } from '@/components/badge/file-analyze-status'
import { Dropdown } from '@/components/dropdown/dropdown'
import { Table } from '@/components/table/table'
import { ColumnType } from '@/components/table/table.type'
import { TextOverflow } from '@/components/text-overflow'
import { TextWithIcon } from '@/components/text-with-icon'

const env = import.meta.env.MODE

export const ArtifactsTable = () => {
  const { data, isLoading } = useListUserSourcedArtifact()
  const { urlParams } = useUrlParams()
  const columns: ColumnType<Artifact>[] = [
    {
      title: 'Third-Party',
      dataIndex: 'companyId',
      width: '17%',
      render: (companyId: Artifact['companyId']) => <ThirdPartyColumn companyId={companyId} />,
      filter: true,
      sort: false,
      accessor: ({ companyId }) => ({
        filterValue: companyId ? [companyId] : [],
      }),
      defaultSelectedFilter: urlParams.thirdParty ? [urlParams.thirdParty] : [],
      optionRender: (companyId) => <ThirdPartyColumn companyId={companyId} />,
    },
    {
      title: 'Artifact',
      width: '9%',
      filter: true,
      accessor: ({ artifactSubcategory }) => ({
        filterValue: artifactSubcategory,
        sortValue: artifactSubcategory,
      }),
      render: ({ artifactSubcategory, expirationDate }: Artifact) => {
        if (!artifactSubcategory) return '–'

        return (
          <TextWithIcon
            text={artifactSubcategory}
            icon={
              expirationDate && <ArtifactExpirationDate expirationDate={expirationDate.toDate()} />
            }
            iconPosition='end'
          />
        )
      },
    },
    {
      title: 'Source',
      dataIndex: 'source',
      width: '13%',
      render: (source: Artifact['source']) => documentSourceLabel[source],
    },
    {
      title: 'Link',
      width: '30%',
      render: ({ name, id }) => (
        <TextOverflow hideTooltip>
          <ArtifactHyperlink artifactId={id}>{name}</ArtifactHyperlink>
        </TextOverflow>
      ),
      search: true,
      accessor: ({ name }) => ({
        searchValue: name,
      }),
    },
    {
      title: 'Status',
      width: '13%',
      render: ({ status, error, dateUpdated }: Artifact) => (
        <DocumentStatusBadge error={error} status={status} lastUpdated={dateUpdated} />
      ),
      filter: true,
      accessor: ({ status, error }) => {
        const maxStatus = _.max(Object.values(documentStatusPriority)) || 0
        return {
          filterValue: error ? 'Failed' : documentStatusLabel[status],
          sortValue: error ? maxStatus + 1 : documentStatusPriority[status],
        }
      },
    },
    {
      title: 'Date Added',
      dataIndex: 'dateAdded',
      width: '12%',
      render: (dateAdded: Artifact['dateAdded']) =>
        dateAdded && <span className='text-md font-light'>{formatDate(dateAdded.toDate())}</span>,
      accessor: ({ dateAdded }) => ({
        sortValue: dateAdded && dateAdded.toDate(),
      }),
    },
    {
      title: '',
      key: 'action',
      width: '6%',
      render: (artifact) => <ArtifactActionDropdown artifact={artifact} />,
    },
  ]
  return (
    <Table
      pagination={{}}
      stickyFilterBar
      columns={columns}
      dataSource={data}
      loading={isLoading}
    />
  )
}

const ThirdPartyColumn = ({ companyId }: { companyId?: string }) => {
  const { data } = useGetCompany(companyId, !!companyId)
  return data?.company?.profile?.name || '-'
}

export const ArtifactActionDropdown = ({ artifact }: { artifact: Artifact }) => {
  const { mutateAsync: deleteOrgArtifact } = useDeleteOrgArtifact()
  const { mutateAsync: retryArtifact } = useRetryArtifact()
  const isSupportUser = useIsSupportUser()

  const shouldShowRetryButton =
    env === 'development' || env === 'preview' || env === 'staging' || isSupportUser

  const documentIsRetryable =
    !!artifact.error ||
    (artifact.status !== DocumentStatus.COMPLETE &&
      artifact.dateUpdated &&
      Date.now() - Number(artifact.dateUpdated.toDate()) > 60 * 60 * 1000)
  const isManualUpload = [
    DocumentSource.MANUAL_UPLOAD,
    DocumentSource.MANUAL_UPLOAD_WITH_COMPANY,
    DocumentSource.MANUAL_UPLOAD_WITH_COMPANY_AND_CATEGORY,
    DocumentSource.LEMA_QUESTIONNAIRE,
  ].includes(artifact.source)
  const isFinalState = DocumentStatus.COMPLETE === artifact.status || artifact.error
  const isArtifactIdCase = artifact.artifact.case === 'artifactId'
  const isDeleteable = isManualUpload && isFinalState && isArtifactIdCase

  const artifactIsPublishable = artifact.source === DocumentSource.WEB && !isArtifactIdCase
  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Delete Artifact',
      content: `Are you sure you want to delete "${artifact.name}"?`,
      centered: true,
      onOk: () => deleteOrgArtifact({ artifactId: artifact.id }),
    })
  }

  const { mutateAsync: publishArtifact } = usePublishArtifact()

  return (
    <Dropdown
      variant='secondary'
      triggerAppearance='headless'
      triggerContent={<MoreVerticalIcon className='text-gray-500' strokeWidth={1} />}
      menuItems={[
        {
          content: <TextWithIcon text='Delete' icon={<Trash2Icon className='size-3.75' />} />,
          itemDisabled: !isDeleteable,
          onClick: showDeleteConfirm,
        },
        {
          content: <TextWithIcon text='Retry' icon={<RefreshCcwIcon className='size-3.75' />} />,
          hide: !shouldShowRetryButton,
          itemDisabled: !documentIsRetryable,
          onClick: () => retryArtifact({ artifactId: artifact.id }),
        },
        {
          content: <TextWithIcon text='Publish' icon={<SendIcon className='size-3.75' />} />,
          hide: !shouldShowRetryButton,
          itemDisabled: !artifactIsPublishable,
          onClick: () => publishArtifact({ artifactId: artifact.id }),
        },
      ]}
    />
  )
}
