// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file customer/management/v1/service.proto (package customer.management.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum customer.management.v1.UserStatus
 */
export enum UserStatus {
  /**
   * @generated from enum value: USER_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: USER_STATUS_INVITED = 1;
   */
  INVITED = 1,

  /**
   * @generated from enum value: USER_STATUS_ENABLED = 2;
   */
  ENABLED = 2,

  /**
   * @generated from enum value: USER_STATUS_DISABLED = 3;
   */
  DISABLED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(UserStatus)
proto3.util.setEnumType(UserStatus, "customer.management.v1.UserStatus", [
  { no: 0, name: "USER_STATUS_UNSPECIFIED" },
  { no: 1, name: "USER_STATUS_INVITED" },
  { no: 2, name: "USER_STATUS_ENABLED" },
  { no: 3, name: "USER_STATUS_DISABLED" },
]);

/**
 * @generated from message customer.management.v1.GetAPIKeyFromAccessTokenRequest
 */
export class GetAPIKeyFromAccessTokenRequest extends Message<GetAPIKeyFromAccessTokenRequest> {
  /**
   * @generated from field: string access_key = 1;
   */
  accessKey = "";

  constructor(data?: PartialMessage<GetAPIKeyFromAccessTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.GetAPIKeyFromAccessTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAPIKeyFromAccessTokenRequest {
    return new GetAPIKeyFromAccessTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAPIKeyFromAccessTokenRequest {
    return new GetAPIKeyFromAccessTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAPIKeyFromAccessTokenRequest {
    return new GetAPIKeyFromAccessTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAPIKeyFromAccessTokenRequest | PlainMessage<GetAPIKeyFromAccessTokenRequest> | undefined, b: GetAPIKeyFromAccessTokenRequest | PlainMessage<GetAPIKeyFromAccessTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetAPIKeyFromAccessTokenRequest, a, b);
  }
}

/**
 * @generated from message customer.management.v1.GetAPIKeyFromAccessTokenResponse
 */
export class GetAPIKeyFromAccessTokenResponse extends Message<GetAPIKeyFromAccessTokenResponse> {
  /**
   * @generated from field: string session_jwt = 1;
   */
  sessionJwt = "";

  constructor(data?: PartialMessage<GetAPIKeyFromAccessTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.GetAPIKeyFromAccessTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_jwt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAPIKeyFromAccessTokenResponse {
    return new GetAPIKeyFromAccessTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAPIKeyFromAccessTokenResponse {
    return new GetAPIKeyFromAccessTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAPIKeyFromAccessTokenResponse {
    return new GetAPIKeyFromAccessTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAPIKeyFromAccessTokenResponse | PlainMessage<GetAPIKeyFromAccessTokenResponse> | undefined, b: GetAPIKeyFromAccessTokenResponse | PlainMessage<GetAPIKeyFromAccessTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetAPIKeyFromAccessTokenResponse, a, b);
  }
}

/**
 * @generated from message customer.management.v1.GetUserByIdRequest
 */
export class GetUserByIdRequest extends Message<GetUserByIdRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<GetUserByIdRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.GetUserByIdRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserByIdRequest {
    return new GetUserByIdRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserByIdRequest {
    return new GetUserByIdRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserByIdRequest {
    return new GetUserByIdRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserByIdRequest | PlainMessage<GetUserByIdRequest> | undefined, b: GetUserByIdRequest | PlainMessage<GetUserByIdRequest> | undefined): boolean {
    return proto3.util.equals(GetUserByIdRequest, a, b);
  }
}

/**
 * @generated from message customer.management.v1.GetUserByIdResponse
 */
export class GetUserByIdResponse extends Message<GetUserByIdResponse> {
  /**
   * @generated from field: customer.management.v1.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<GetUserByIdResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.GetUserByIdResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserByIdResponse {
    return new GetUserByIdResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserByIdResponse {
    return new GetUserByIdResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserByIdResponse {
    return new GetUserByIdResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserByIdResponse | PlainMessage<GetUserByIdResponse> | undefined, b: GetUserByIdResponse | PlainMessage<GetUserByIdResponse> | undefined): boolean {
    return proto3.util.equals(GetUserByIdResponse, a, b);
  }
}

/**
 * @generated from message customer.management.v1.RemoveUserFromTenantRequest
 */
export class RemoveUserFromTenantRequest extends Message<RemoveUserFromTenantRequest> {
  /**
   * @generated from field: string login_id = 1;
   */
  loginId = "";

  constructor(data?: PartialMessage<RemoveUserFromTenantRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.RemoveUserFromTenantRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "login_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveUserFromTenantRequest {
    return new RemoveUserFromTenantRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveUserFromTenantRequest {
    return new RemoveUserFromTenantRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveUserFromTenantRequest {
    return new RemoveUserFromTenantRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveUserFromTenantRequest | PlainMessage<RemoveUserFromTenantRequest> | undefined, b: RemoveUserFromTenantRequest | PlainMessage<RemoveUserFromTenantRequest> | undefined): boolean {
    return proto3.util.equals(RemoveUserFromTenantRequest, a, b);
  }
}

/**
 * @generated from message customer.management.v1.RemoveUserFromTenantResponse
 */
export class RemoveUserFromTenantResponse extends Message<RemoveUserFromTenantResponse> {
  constructor(data?: PartialMessage<RemoveUserFromTenantResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.RemoveUserFromTenantResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveUserFromTenantResponse {
    return new RemoveUserFromTenantResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveUserFromTenantResponse {
    return new RemoveUserFromTenantResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveUserFromTenantResponse {
    return new RemoveUserFromTenantResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveUserFromTenantResponse | PlainMessage<RemoveUserFromTenantResponse> | undefined, b: RemoveUserFromTenantResponse | PlainMessage<RemoveUserFromTenantResponse> | undefined): boolean {
    return proto3.util.equals(RemoveUserFromTenantResponse, a, b);
  }
}

/**
 * @generated from message customer.management.v1.ListUsersRequest
 */
export class ListUsersRequest extends Message<ListUsersRequest> {
  constructor(data?: PartialMessage<ListUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.ListUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListUsersRequest | PlainMessage<ListUsersRequest> | undefined, b: ListUsersRequest | PlainMessage<ListUsersRequest> | undefined): boolean {
    return proto3.util.equals(ListUsersRequest, a, b);
  }
}

/**
 * @generated from message customer.management.v1.ListUsersResponse
 */
export class ListUsersResponse extends Message<ListUsersResponse> {
  /**
   * @generated from field: repeated customer.management.v1.User users = 1;
   */
  users: User[] = [];

  constructor(data?: PartialMessage<ListUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.ListUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListUsersResponse | PlainMessage<ListUsersResponse> | undefined, b: ListUsersResponse | PlainMessage<ListUsersResponse> | undefined): boolean {
    return proto3.util.equals(ListUsersResponse, a, b);
  }
}

/**
 * @generated from message customer.management.v1.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: repeated string login_ids = 2;
   */
  loginIds: string[] = [];

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: customer.management.v1.UserStatus status = 5;
   */
  status = UserStatus.UNSPECIFIED;

  /**
   * @generated from field: optional string avatar_url = 6;
   */
  avatarUrl?: string;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "login_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(UserStatus) },
    { no: 6, name: "avatar_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message customer.management.v1.ListOrgsRequest
 */
export class ListOrgsRequest extends Message<ListOrgsRequest> {
  constructor(data?: PartialMessage<ListOrgsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.ListOrgsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrgsRequest {
    return new ListOrgsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrgsRequest {
    return new ListOrgsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrgsRequest {
    return new ListOrgsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListOrgsRequest | PlainMessage<ListOrgsRequest> | undefined, b: ListOrgsRequest | PlainMessage<ListOrgsRequest> | undefined): boolean {
    return proto3.util.equals(ListOrgsRequest, a, b);
  }
}

/**
 * @generated from message customer.management.v1.ListOrgsResponse
 */
export class ListOrgsResponse extends Message<ListOrgsResponse> {
  /**
   * @generated from field: repeated string org_ids = 1;
   */
  orgIds: string[] = [];

  constructor(data?: PartialMessage<ListOrgsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "customer.management.v1.ListOrgsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "org_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrgsResponse {
    return new ListOrgsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrgsResponse {
    return new ListOrgsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrgsResponse {
    return new ListOrgsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListOrgsResponse | PlainMessage<ListOrgsResponse> | undefined, b: ListOrgsResponse | PlainMessage<ListOrgsResponse> | undefined): boolean {
    return proto3.util.equals(ListOrgsResponse, a, b);
  }
}

