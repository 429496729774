import { Settings } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { useSettingsItems } from '@/pages/settings-page/use-settings-items.hook'

import { Dropdown } from '@/components/dropdown/dropdown'
import { getTabItemIndex } from '@/components/tabs/types'
import { UserProfileButton } from '@/components/user/user-profile-button'

export const IconsToolBar = () => {
  const settingsItems = useSettingsItems()
  const navigate = useNavigate()

  return (
    <div className='flex items-center gap-6'>
      <Dropdown
        triggerAppearance={'headless'}
        menuItems={settingsItems.map((tabItem) => {
          const index = getTabItemIndex(tabItem)
          const { itemDisabled, labelIcon, label } = tabItem
          return {
            itemDisabled,
            onClick: () => navigate(`settings?settingSection=${index}`),
            icon: labelIcon,
            content: label,
          }
        })}
        triggerContent={
          <div className='duration-400 group rounded p-2 transition ease-in-out hover:bg-gray-700'>
            <Settings className='text-inherit size-5 transition duration-300 group-hover:rotate-90 group-hover:text-white' />
          </div>
        }
      />

      <UserProfileButton />
    </div>
  )
}
