// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file notifications/v1/notifications_service.proto (package notifications.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum notifications.v1.NotificationType
 */
export enum NotificationType {
  /**
   * @generated from enum value: NOTIFICATION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_QUESTIONNAIRE_COMPLETED = 1;
   */
  QUESTIONNAIRE_COMPLETED = 1,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_QUESTIONNAIRE_DELETED = 2;
   */
  QUESTIONNAIRE_DELETED = 2,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_QUESTION_UPDATED = 3;
   */
  QUESTION_UPDATED = 3,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_THREAD_MARKED_UNRESOLVED = 4;
   */
  THREAD_MARKED_UNRESOLVED = 4,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_USER_ENTERED_QUESTIONNAIRE = 5;
   */
  USER_ENTERED_QUESTIONNAIRE = 5,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_NEW_USER_INVITED = 6;
   */
  NEW_USER_INVITED = 6,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_COMMENT_ADDED = 7;
   */
  COMMENT_ADDED = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(NotificationType)
proto3.util.setEnumType(NotificationType, "notifications.v1.NotificationType", [
  { no: 0, name: "NOTIFICATION_TYPE_UNSPECIFIED" },
  { no: 1, name: "NOTIFICATION_TYPE_QUESTIONNAIRE_COMPLETED" },
  { no: 2, name: "NOTIFICATION_TYPE_QUESTIONNAIRE_DELETED" },
  { no: 3, name: "NOTIFICATION_TYPE_QUESTION_UPDATED" },
  { no: 4, name: "NOTIFICATION_TYPE_THREAD_MARKED_UNRESOLVED" },
  { no: 5, name: "NOTIFICATION_TYPE_USER_ENTERED_QUESTIONNAIRE" },
  { no: 6, name: "NOTIFICATION_TYPE_NEW_USER_INVITED" },
  { no: 7, name: "NOTIFICATION_TYPE_COMMENT_ADDED" },
]);

/**
 * @generated from enum notifications.v1.Actor
 */
export enum Actor {
  /**
   * @generated from enum value: ACTOR_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACTOR_RECIPIENT = 1;
   */
  RECIPIENT = 1,

  /**
   * @generated from enum value: ACTOR_ASSESSOR = 2;
   */
  ASSESSOR = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Actor)
proto3.util.setEnumType(Actor, "notifications.v1.Actor", [
  { no: 0, name: "ACTOR_UNSPECIFIED" },
  { no: 1, name: "ACTOR_RECIPIENT" },
  { no: 2, name: "ACTOR_ASSESSOR" },
]);

/**
 * @generated from message notifications.v1.ListSlackChannelsRequest
 */
export class ListSlackChannelsRequest extends Message<ListSlackChannelsRequest> {
  constructor(data?: PartialMessage<ListSlackChannelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "notifications.v1.ListSlackChannelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSlackChannelsRequest {
    return new ListSlackChannelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSlackChannelsRequest {
    return new ListSlackChannelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSlackChannelsRequest {
    return new ListSlackChannelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSlackChannelsRequest | PlainMessage<ListSlackChannelsRequest> | undefined, b: ListSlackChannelsRequest | PlainMessage<ListSlackChannelsRequest> | undefined): boolean {
    return proto3.util.equals(ListSlackChannelsRequest, a, b);
  }
}

/**
 * @generated from message notifications.v1.ListSlackChannelsResponse
 */
export class ListSlackChannelsResponse extends Message<ListSlackChannelsResponse> {
  /**
   * @generated from field: repeated notifications.v1.SlackChannel channels = 1;
   */
  channels: SlackChannel[] = [];

  constructor(data?: PartialMessage<ListSlackChannelsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "notifications.v1.ListSlackChannelsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channels", kind: "message", T: SlackChannel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSlackChannelsResponse {
    return new ListSlackChannelsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSlackChannelsResponse {
    return new ListSlackChannelsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSlackChannelsResponse {
    return new ListSlackChannelsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSlackChannelsResponse | PlainMessage<ListSlackChannelsResponse> | undefined, b: ListSlackChannelsResponse | PlainMessage<ListSlackChannelsResponse> | undefined): boolean {
    return proto3.util.equals(ListSlackChannelsResponse, a, b);
  }
}

/**
 * @generated from message notifications.v1.SlackChannel
 */
export class SlackChannel extends Message<SlackChannel> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: bool is_private = 3;
   */
  isPrivate = false;

  constructor(data?: PartialMessage<SlackChannel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "notifications.v1.SlackChannel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SlackChannel {
    return new SlackChannel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SlackChannel {
    return new SlackChannel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SlackChannel {
    return new SlackChannel().fromJsonString(jsonString, options);
  }

  static equals(a: SlackChannel | PlainMessage<SlackChannel> | undefined, b: SlackChannel | PlainMessage<SlackChannel> | undefined): boolean {
    return proto3.util.equals(SlackChannel, a, b);
  }
}

/**
 * @generated from message notifications.v1.TriggerNotificationRequest
 */
export class TriggerNotificationRequest extends Message<TriggerNotificationRequest> {
  /**
   * @generated from field: notifications.v1.NotificationType type = 1;
   */
  type = NotificationType.UNSPECIFIED;

  /**
   * @generated from field: string room_id = 2;
   */
  roomId = "";

  /**
   * @generated from field: notifications.v1.Actor actor = 3;
   */
  actor = Actor.UNSPECIFIED;

  /**
   * @generated from field: string user_id = 4;
   */
  userId = "";

  /**
   * use for NOTIFICATION_TYPE_COMMENT_ADDED and NOTIFICATION_TYPE_THREAD_MARKED_UNRESOLVED
   *
   * @generated from field: optional string thread_id = 5;
   */
  threadId?: string;

  /**
   * use for NOTIFICATION_TYPE_COMMENT_ADDED
   *
   * @generated from field: optional string comment = 6;
   */
  comment?: string;

  /**
   * @generated from field: optional string magic_link = 7;
   */
  magicLink?: string;

  /**
   * @generated from field: optional string question_id = 8;
   */
  questionId?: string;

  constructor(data?: PartialMessage<TriggerNotificationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "notifications.v1.TriggerNotificationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(NotificationType) },
    { no: 2, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "actor", kind: "enum", T: proto3.getEnumType(Actor) },
    { no: 4, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "thread_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TriggerNotificationRequest {
    return new TriggerNotificationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TriggerNotificationRequest {
    return new TriggerNotificationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TriggerNotificationRequest {
    return new TriggerNotificationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TriggerNotificationRequest | PlainMessage<TriggerNotificationRequest> | undefined, b: TriggerNotificationRequest | PlainMessage<TriggerNotificationRequest> | undefined): boolean {
    return proto3.util.equals(TriggerNotificationRequest, a, b);
  }
}

/**
 * @generated from message notifications.v1.TriggerNotificationResponse
 */
export class TriggerNotificationResponse extends Message<TriggerNotificationResponse> {
  constructor(data?: PartialMessage<TriggerNotificationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "notifications.v1.TriggerNotificationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TriggerNotificationResponse {
    return new TriggerNotificationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TriggerNotificationResponse {
    return new TriggerNotificationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TriggerNotificationResponse {
    return new TriggerNotificationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TriggerNotificationResponse | PlainMessage<TriggerNotificationResponse> | undefined, b: TriggerNotificationResponse | PlainMessage<TriggerNotificationResponse> | undefined): boolean {
    return proto3.util.equals(TriggerNotificationResponse, a, b);
  }
}

