import { Timestamp } from '@bufbuild/protobuf'
import { Timeline } from 'antd'
import { VariantProps, cva } from 'class-variance-authority'
import { CheckIcon, CircleIcon, DotIcon, InfoIcon, LoaderIcon, XIcon } from 'lucide-react'
import { ReactNode, useEffect, useState } from 'react'

import { DocumentError, DocumentStatus } from '@/gen/inventory/v1/artifact_service_pb'

import { LEMA_SUPPORT_EMAIL } from '@/const/contact'
import { documentErrorLabel, documentStatusLabel } from '@/const/label'

import { useTrackCallback } from '@/lib/analytics/events'
import { timeSince } from '@/lib/date'

import { Hyperlink } from '@/components/hyperlink'
import { Badge } from '@/components/ui/badge'
import { Tooltip } from '@/components/ui/tooltip'

interface DocumentStatusBadgeProps {
  status: DocumentStatus
  error?: DocumentError
  lastUpdated?: Timestamp
}

export const DocumentStatusBadge = ({ status, error, lastUpdated }: DocumentStatusBadgeProps) => {
  const trackArtifactErrorTooltipOpen = useTrackCallback('artifact.error-tooltip.open')
  const isTooltipDisabled = status === DocumentStatus.PENDING || status === DocumentStatus.COMPLETE

  return (
    <div className='flex items-center justify-between gap-2'>
      <Tooltip
        onOpenChange={(open) => {
          if (open) {
            trackArtifactErrorTooltipOpen({
              error: documentErrorLabel[error || DocumentError.UNSPECIFIED],
            })
          }
        }}
        tooltipDisabled={isTooltipDisabled}
        className='p-0'
        trigger={
          <BaseDocumentStatusBadge status={status} error={error} hoverable={!isTooltipDisabled} />
        }
      >
        {error !== DocumentError.UNSPECIFIED && error && (
          <div className='flex max-w-72 flex-col gap-3 border-b p-4'>
            <BaseDocumentStatusBadge highlighted status={status} error={error} />
            {artifactErrorComponent[error]}
          </div>
        )}
        <div className='p-5 pt-8'>
          <Timeline items={getTimelineItems(status, error, lastUpdated)} />
        </div>
      </Tooltip>
    </div>
  )
}

const getTimelineItems = (
  currentStatus: DocumentStatus,
  error?: DocumentError,
  lastUpdated?: Timestamp,
) => {
  const statuses = [
    DocumentStatus.PENDING,
    DocumentStatus.CLASSIFYING,
    DocumentStatus.ANALYZING,
    DocumentStatus.COMPLETE,
  ]

  return statuses.map((status) => {
    const isCurrent = status === currentStatus
    const isCompleted = statuses.indexOf(status) < statuses.indexOf(currentStatus)

    let dot: React.ReactNode
    if (error && isCurrent) {
      dot = <XIcon className='size-2.5 text-red-500' />
    } else if (isCompleted || (isCurrent && status === DocumentStatus.COMPLETE)) {
      dot = <DotIcon strokeWidth={18} size={7} className='text-green-500' />
    } else if (isCurrent) {
      dot = <CircleIcon strokeWidth={6} size={7} className='text-green-500' />
    } else {
      dot = <DotIcon strokeWidth={18} size={7} className='text-gray-300' />
    }

    return {
      children: (
        <TimelineItem
          label={documentStatusLabel[status]}
          showLastUpdated={isCurrent && !error}
          lastUpdated={lastUpdated}
        />
      ),
      dot,
    }
  })
}

type TimelineItemProps = {
  label: string
  lastUpdated?: Timestamp
  showLastUpdated?: boolean
}

const TimelineItem = ({ label, lastUpdated, showLastUpdated }: TimelineItemProps) => {
  const [{ unit, value }, setTimeSince] = useState(() =>
    timeSince(lastUpdated?.toDate() || new Date()),
  )

  useEffect(() => {
    if (!showLastUpdated) return () => {}
    const interval = setInterval(() => {
      const newTimeSince = timeSince(lastUpdated?.toDate() || new Date())
      if (newTimeSince.unit !== unit || newTimeSince.value !== value) {
        setTimeSince(newTimeSince)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [lastUpdated, unit, value, showLastUpdated])

  return (
    <div className='flex items-center justify-between gap-3'>
      {label}
      {showLastUpdated && (
        <div className='text-sm font-light text-gray-400'>{`${value} ${unit}`}</div>
      )}
    </div>
  )
}

const contactLink = (
  <Hyperlink target='_blank' href={`mailto:${LEMA_SUPPORT_EMAIL}`}>
    Contact us
  </Hyperlink>
)

const artifactErrorComponent: Record<DocumentError, ReactNode> = {
  [DocumentError.UNSPECIFIED]: '',
  [DocumentError.EMPTY]: 'File could not be processed because it contains no textual data.',
  [DocumentError.ENCRYPTED]:
    'File is encrypted and cannot be processed by Lema. Un-encrypt the file and upload it again.',
  [DocumentError.UNKNOWN_CATEGORY]: (
    <div>File could not be classified into a category. {contactLink} for assistance.</div>
  ),
  [DocumentError.UNSUPPORTED_TYPE]:
    'File type is not supported. Convert to a supported format (pdf, txt, doc, docx, xslx) and try again',
  [DocumentError.UNKNOWN_COMPANY]:
    'File could not be associated with a third-party. Try uploading it via the third-party tray.',
  [DocumentError.UNKNOWN_ERROR]: (
    <div>File could not be processed. {contactLink} for assistance.</div>
  ),
}

const documentStatusVariant = cva(
  'text-md flex w-fit items-center gap-1 h-6 justify-center whitespace-nowrap px-2 rounded',
  {
    variants: {
      status: {
        [DocumentStatus.PENDING]: 'bg-purple-100 text-purple-600',
        [DocumentStatus.CLASSIFYING]: 'text-purple-500',
        [DocumentStatus.ANALYZING]: 'text-purple-500',
        [DocumentStatus.COMPLETE]: 'text-green-500',
        [DocumentStatus.UNSPECIFIED]: '',
      },
      error: {
        [DocumentError.EMPTY]: 'text-gray-500 bg-gray-100 border-gray-200',
        [DocumentError.ENCRYPTED]: 'text-gray-500 bg-gray-100 border-gray-200',
        [DocumentError.UNKNOWN_CATEGORY]: 'text-gray-500 bg-gray-100 border-gray-200',
        [DocumentError.UNSUPPORTED_TYPE]: 'text-gray-500 bg-gray-100 border-gray-200',
        [DocumentError.UNKNOWN_COMPANY]: 'text-gray-500 bg-gray-100 border-gray-200',
        [DocumentError.UNKNOWN_ERROR]: 'text-red-500 bg-red-50 border-red-100',
        [DocumentError.UNSPECIFIED]: '',
      },
      hoverable: {
        true: 'hover:bg-gray-200',
        false: '',
      },
      highlighted: {
        true: '',
        false: 'bg-transparent border-none',
      },
    },
    defaultVariants: {
      highlighted: false,
      hoverable: false,
    },
  },
)

type BaseDocumentStatusBadgeProps = VariantProps<typeof documentStatusVariant>

const BaseDocumentStatusBadge = ({
  status,
  error,
  hoverable,
  highlighted,
}: BaseDocumentStatusBadgeProps) => {
  if (!status) return null
  return (
    <Badge
      variant='outline'
      className={documentStatusVariant({
        status,
        error,
        hoverable,
        highlighted,
      })}
    >
      <DocumentStatusIcon status={status} error={error} />
      {error ? documentErrorLabel[error] : documentStatusLabel[status]}
      {hoverable && <InfoIcon size={12.5} strokeWidth={1.5} />}
    </Badge>
  )
}

type DocumentStatusIconProps = {
  status: DocumentStatus
  error?: DocumentError | null
}

const DocumentStatusIcon = ({ status, error }: DocumentStatusIconProps) => {
  if (error) {
    return <XIcon size={14} strokeWidth={1.5} />
  } else if (status === DocumentStatus.COMPLETE) {
    return <CheckIcon size={14} strokeWidth={1.5} />
  } else if (status === DocumentStatus.ANALYZING || status === DocumentStatus.CLASSIFYING) {
    return <LoaderIcon size={14} strokeWidth={1.5} className='animate-spin duration-2500' />
  }
  return null
}
