import { PropsWithChildren } from 'react'

import { useGetArtifactPreviewUrl, useGetArtifactRevisionPreviewUrl } from '@/api/artifact.hook'

import { useTrackCallback } from '@/lib/analytics/events'

import { Hyperlink } from '@/components/hyperlink'

type ArtifactHyperlinkProps = {
  artifactId: string
} & PropsWithChildren

// This component is used to create a hyperlink to a artifact
// If the artifact is a PDF, it will open the artifact in a new tab
// If the artifact is not a PDF, it will download the artifact
export const ArtifactHyperlink = ({ artifactId, children }: ArtifactHyperlinkProps) => {
  const trackArtifactView = useTrackCallback('artifact.view')

  const { data } = useGetArtifactPreviewUrl(artifactId)

  return (
    <Hyperlink onClick={() => trackArtifactView({ artifactId })} href={data}>
      {children}
    </Hyperlink>
  )
}

export const PublicArtifactHyperlink = ({ artifactId, children }: ArtifactHyperlinkProps) => {
  const trackArtifactView = useTrackCallback('artifact.view')

  const { data } = useGetArtifactRevisionPreviewUrl(artifactId)

  return (
    <Hyperlink onClick={() => trackArtifactView({ artifactId })} href={data}>
      {children}
    </Hyperlink>
  )
}
