import { useMutation, useQueryClient } from '@tanstack/react-query'

import { publishArtifact } from '@/gen/artifacts/store/v1/store_service-ArtifactStoreService_connectquery'
import {
  retryArtifact,
  uploadArtifact,
} from '@/gen/artifacts/store/v1/store_service-OrgArtifactStoreService_connectquery'
import {
  listCompanyArtifact,
  listUserSourcedArtifact,
} from '@/gen/inventory/v1/artifact_service-ArtifactService_connectquery'

export const useUploadArtifact = () => {
  const queryClient = useQueryClient()
  const { queryKey: listCompanyArtifactQueryKey } = listCompanyArtifact.useQuery()
  const { queryKey: listUserSourcedArtifactQueryKey } = listUserSourcedArtifact.useQuery()
  return useMutation(uploadArtifact.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listCompanyArtifactQueryKey)
      queryClient.invalidateQueries(listUserSourcedArtifactQueryKey)
    },
  })
}

export const useRetryArtifact = () => {
  const queryClient = useQueryClient()
  return useMutation(retryArtifact.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listUserSourcedArtifact.getQueryKey())
      queryClient.invalidateQueries(listCompanyArtifact.getQueryKey())
    },
  })
}

export const usePublishArtifact = () => {
  const queryClient = useQueryClient()
  return useMutation(publishArtifact.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listUserSourcedArtifact.getQueryKey())
      queryClient.invalidateQueries(listCompanyArtifact.getQueryKey())
    },
  })
}
